import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignLocationComponent } from './components/campaign-location/campaign-location.component';


@NgModule({
  declarations: [CampaignLocationComponent],
  exports: [
    CampaignLocationComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class CampaignLocationModule { }
