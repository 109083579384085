<section>
      <h3>{{ format | formatName }}</h3>

      <div class="row">

        <div class="col-12 mb-4">
          <h4>Dimensions</h4>
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>Width [px]</th>
                <th>Height [px]</th>
                <th>Aspect Ratio</th>
            </thead>
            <tbody>
              <tr *ngFor="let spec of data[format]">
                <td class="text-right">{{ spec['Width'] }}</td>
                <td class="text-right">{{ spec['Height'] }}</td>
                <td class="text-right">{{ spec['Aspect Ratio']}}</td>
              </tr>
            </tbody>
          </table>
        </div>

<!--        <div class="col-1">&nbsp;</div>-->

        <div class="col-6" *ngIf="IMAGE_TYPE.includes(contentType)">
          <h4>Static Content</h4>
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="header">Supported formats</td>
                <td>{{ data[format][0]['image']['Supported Formats'] }}</td>
              </tr>
              <tr>
                <td class="header">Copy Length</td>
                <td>{{ data[format][0]['image']['Copy Length'] }}</td>
              </tr>
              <tr>
                <td class="header">File Size</td>
                <td>{{ data[format][0]['image']['File Size'] }}</td>
              </tr>
            </tbody>
          </table>
        </div>

<!--        <div class="col-6" *ngIf="VIDEO_TYPE.includes(contentType)">-->
<!--          <h4>Motion Content</h4>-->
<!--          <table class="table table-borderless">-->
<!--            <tbody>-->
<!--              <tr>-->
<!--                <td class="header">Supported formats</td>-->
<!--                <td>{{ data[format][0]['video']['Supported Formats'] }}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="header">Copy Length</td>-->
<!--                <td>{{ data[format][0]['video']['Copy Length'] }}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="header">File Size</td>-->
<!--                <td>{{ data[format][0]['video']['File Size'] }}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="header">Sound</td>-->
<!--                <td>{{ data[format][0]['video']['Sound'] }}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="header">Frame Rate</td>-->
<!--                <td>{{ data[format][0]['video']['Frame Rate'] }}</td>-->
<!--              </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->

      </div>
    </section>
