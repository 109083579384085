<div class="panel">
  <div *ngIf="error" class="error-notification">{{ error }}</div>
  <div class="overflow-hidden">
    <form>
      <div class="form-group">
        <label for="login">Company name:</label>
        <input id="name"
               class="form-control"
               type="text"
               (ngModelChange)="error = null"
               [(ngModel)]="data.name"
               [ngModelOptions]="{standalone: true}"/>
      </div>
      <div class="form-group">
        <label for="login">E-mail address:</label>
        <input id="login"
               class="form-control"
               type="text"
               (ngModelChange)="error = null"
               [(ngModel)]="data.email"
               [ngModelOptions]="{standalone: true}"/>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input id="password"
               class="form-control"
               type="password"
               (ngModelChange)="error = null"
               [(ngModel)]="data.password"
               [ngModelOptions]="{standalone: true}"/>
      </div>
      <button class="Polaris-Button Polaris-Button--primary float-right mt-4"
              [disabled]="!isFormValid"
              (click)="signUp()">Sign Up</button>
    </form>
  </div>
  <div class="text-center mt-5">
    Already have account?<br><a routerLink="/signin"><b>Sign in here</b></a>
  </div>

</div>

<div class="bg"></div>
<app-spinner *ngIf="processing"></app-spinner>
