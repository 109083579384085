<div class="row inner">
  <div class="col-7 generator-output">
    <app-artwork-preview
      [previewSrc]="previewUrl"
      [fileType]="'image'"
      [chosenFormat]="selectedFormat"
      [uploadButton]="false">
    </app-artwork-preview>
  </div>
  <div class="col-5 h-100 overflow-auto">

    <h4 class="mt-5">Budget</h4>
    <div class="form-group mb-3">
      <app-range-step-slider
          [value]="campaignConfig.budget"
          [min]="0"
          [max]="5000"
          [steps]="[1, 2, 5, 10, 100]"
          [options]="{prefix: '£'}"
          (change)="updateCampaign('budget', $event)">
      </app-range-step-slider>
    </div>

    <h4 class="mt-5">Daily Budget</h4>
    <div class="form-group mb-3">
      <app-range-step-slider
        [value]="campaignConfig.dailyBudget"
        [min]="0"
        [max]="1000"
        [steps]="[1, 2, 5, 10, 100]"
        [options]="{prefix: '£'}"
        (change)="updateCampaign('dailyBudget', $event)">
    </app-range-step-slider>
    </div>

    <h4 class="mt-5 mb-4">Location</h4>
    <app-campaign-location
      [latitude]="campaign.latitude"
      [btnClass]="'Polaris-Button'"
      [btnClassConfirm]="'Polaris-Button Polaris-Button--primary'"
      [longitude]="campaign.longitude"
      [postalCode]="campaign.offer_postcode"
      [radius]="campaign.offer_radius"
      (updated)="onLocationChange($event)">
    </app-campaign-location>

    <h4 class="mt-5">Schedule</h4>
    <ngx-daterangepicker-material
            [startDate]="selectedDates.startDate"
            [endDate]="selectedDates.endDate"
            [autoApply]="true"
            [minDate]="minDate"
            (choosedDate)="choosedDate($event)">
    </ngx-daterangepicker-material>


  </div>

  <app-spinner *ngIf="processing"></app-spinner>

</div>



