import { Component, OnInit } from '@angular/core';
import { ShopifyService } from '../../services/shopify.service';
import { ControlService } from '../../services/control.service';
import { CampaignService } from '../../services/campaign.service';

const NEXT_STEP = 1;
const PRODUCTS_PER_PAGE = 20;

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss']
})
export class ProductSelectorComponent implements OnInit {

  data: any;
  processing = false;
  public searchString = '';

  constructor(
    private service: ShopifyService,
    private controlSvc: ControlService,
    private campaignSvc: CampaignService,
  ) { }

  ngOnInit(): void {
    this.getProducts();
  }

  public getProducts(prev: string = null, next: string = null): void {
    this.processing = true;
    this.data = null;

    // this.service.query('products', query).subscribe(data => {
    //   this.processing = false;
    //   this.data = data;
    // });

    this.service.queryProducts(this.query, prev, next).subscribe(data => {
      this.processing = false;
      this.data = data;
      console.log(data);
    },
      () => this.processing = false);
  }

  public search(val: string): void {
    console.log(val);
    this.searchString = val;
    this.getProducts();
  }

  get query(): string {
    return (!this.searchString || this.searchString.trim() === '') ? null : this.searchString;
  }

  getImages(product: any): any {
    return this.service.getNodes(product.images);
  }

  selectItem(product): void {
    this.campaignSvc.artwork = null;
    this.controlSvc.product = product;
    this.controlSvc.currentStep = NEXT_STEP;
  }

}
