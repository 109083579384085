export const ERROR_CODES = [
  {
    code: 'auth/invalid-email',
    message: 'Invalid email address',
  },
  {
    code: 'auth/user-not-found',
    message: 'Invalid user',
  },
  {
    code: 'auth/invalid-email',
    message: 'Invalid password',
  },
]