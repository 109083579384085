import { Component, Input, OnInit } from '@angular/core';
import { STEPS } from '@const/steps.consts';
import { ControlService } from '../../services/control.service';
import { Observable } from 'rxjs';
import { FcAuthService } from '../../services/fc-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() skipStepper = false;
  @Input() title = 'Welcome';

  steps = STEPS;

  constructor(
    private controlSvc: ControlService,
    private flowCityAuth: FcAuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  get currentStep(): Observable<number> {
    return this.controlSvc.currentStep$;
  }

  setStep(step: number): void {
    this.controlSvc.currentStep = step;
  }

  get isAuthenticated(): Observable<boolean> {
    return this.flowCityAuth.isAuthenticated;
  }

  goToYourCampaigns(): void {
    this.flowCityAuth.refreshSessionData().subscribe(
      () => this.router.navigateByUrl('/your-campaigns')
    );
  }

}
