<div class="panel">
  <div *ngIf="error" class="error-notification">{{ error }}</div>
  <h2 class="text-center">Welcome back!</h2>
  <p class="text-center bm-5">Please sign in using your<br>email and password</p>
  <form>
    <div class="form-group">
      <label for="login">E-mail address:</label>
      <input id="login"
             class="form-control"
             type="text"
             (ngModelChange)="error = null"
             [(ngModel)]="data.email"
             [ngModelOptions]="{standalone: true}"/>
    </div>
    <div class="form-group">
      <label for="password">Password:</label>
      <input id="password"
             class="form-control"
             type="password"
             (ngModelChange)="error = null"
             [(ngModel)]="data.password"
             [ngModelOptions]="{standalone: true}"/>
    </div>
    <button class="Polaris-Button Polaris-Button--primary float-right mt-4"
            [disabled]="!isFormValid"
            (click)="signIn()">Sign In</button>
  </form>
</div>

<div class="bg"></div>
<app-spinner *ngIf="processing"></app-spinner>
