import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-format-specs',
  templateUrl: './format-specs.component.html',
  styleUrls: ['./format-specs.component.scss']
})
export class FormatSpecsComponent {

  @Input() format: any;
  @Input() contentType: any;
  @Input() data: any;

  constructor() { }

  IMAGE_TYPE = ['Image', 'Image & Video'];
  VIDEO_TYPE = ['Video', 'Image & Video'];

}
