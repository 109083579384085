<ng-container *ngIf="budget">
  <div class="indicator"
       [style.color]="color"
       [attr.data-percent]="percentStr">
    <svg
       class="progress-ring"
       width="40"
       height="40">
      <circle
          class="progress-ring__bg"
          stroke="#dddddd"
          stroke-width="3"
          fill="transparent"
          r="18"
          cx="20"
          cy="20"/>
      <circle
          #circle
          class="progress-ring__circle"
          [style.stroke]="color"
          stroke-width="3"
          fill="transparent"
          r="18"
          cx="20"
          cy="20"/>
    </svg>


  </div>
  <div class="desc">
    <p>Estimated budget spend</p>
    <p class="credits"><span [style.color]="color">{{ spend | currency:'GBP':'symbol-narrow':'1.2-2' }}</span> of <span>{{ budget | currency:'GBP':'symbol-narrow':'1.2-2' }}</span></p>
  </div>
</ng-container>
