import { Component, OnInit } from '@angular/core';
import { FcAuthService } from '../../services/fc-auth.service';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  done = false;

  constructor(
    private flowCityAuth: FcAuthService,
  ) { }

  ngOnInit(): void {
    this.flowCityAuth.signOut().subscribe(() => this.done = true);
  }

}
