import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ICampaignLocation } from '@module/campaign-location/campaign-location.types';

const GOOGLE_URL_BASE = 'https://maps.googleapis.com/maps/api/staticmap';

@Injectable({
  providedIn: 'root'
})
export class CampaignLocationService {

  constructor(
    private http: HttpClient,
  ) { }

  getImage(location: ICampaignLocation, size= 150): Observable<any> {
    let center = location.postalCode;
    if (location.latitude && location.longitude) {
      center = `${location.latitude},${location.longitude}`;
    }
    const uri = `${GOOGLE_URL_BASE}?center=${center}&zoom=13&size=${size}x${size}&maptype=roadmap&key=${environment.mapsApiKey}`;
    return this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json'})
      .pipe(
        switchMap((response: any) => {
          return new Observable(subscriber => {
            const dataType = response.body.type;
            const binaryData = [];
            binaryData.push(response.body);
            const blob = new Blob(binaryData, {type: 'image/png'});
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              subscriber.next(reader.result);
              subscriber.complete();
            };
          });
        })
      );
  }
}
