import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { CurrencyMaskConfig } from 'ngx-currency';
import { Slider } from 'primeng/slider';


@Component({
  selector: 'app-range-step-slider',
  templateUrl: './range-step-slider.component.html',
  styleUrls: ['./range-step-slider.component.scss'],
})
export class RangeStepSliderComponent implements OnInit, OnChanges {

  @ViewChild('slider', { static: false }) slider: Slider;

  @Input() value: number;
  @Input() min: number;
  @Input() max: number;
  @Input() steps;
  @Input() postfix: string;
  @Input() numPrecision: number = 0;
  @Input() options: Partial<CurrencyMaskConfig>;
  @Input() thumbPointerSubline: string;
  @Input() disabled;
  @Output() change = new EventEmitter();

  _lastValue: number;

  sliderPos: number;

  distributions: number;
  discretePoints: number;
  rangeValue: any;

  sliderUsed = true;

  editable = false;
  manualValue: number;

  currencyOptions: Partial<CurrencyMaskConfig>;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.rangeValue = this.mapValueToRangeValue(changes.value.currentValue);
      this.manualValue = changes.value.currentValue;
      setTimeout(() => this.onChange(null));
    }
  }

  ngOnInit() {
    this.currencyOptions = {...{align: 'right', precision: 0, prefix: ''}, ...this.options};
  }

  scaleTransform(input): number {
    const stepTransforms = this.steps.map((s, i) => {
      const setCount = Math.min(Math.ceil(input - (this.discretePoints * i / this.distributions)), Math.round(this.discretePoints / this.distributions));
      return setCount > 0 ? setCount * s : 0;
    });

    let lastStep = 0;
    const out = stepTransforms.reduce((total, num, i) => {
      if (num) {
        lastStep = i;
      }
      return total + num;
    }).toFixed(this.numPrecision);
    const currentUnit = this.steps[lastStep];
    return parseFloat(Math.min(
      Math.round((out / currentUnit)) * currentUnit,  // round to nearest step
      this.max
    ).toFixed(this.numPrecision));
  }

  setValue(value, manual: boolean = false): void {
    this.sliderUsed = !manual;
    if (!manual) {
      this.value = this.scaleTransform(this.rangeValue);
    }
    if (this._lastValue === this.value) {
      return;
    }
    this._lastValue = this.value;
    this.change.emit(this.value);
  }

  toggleEditable(update: boolean) {
    if (!this.editable) {
      this.manualValue = this.value;
    } else {
      if (update) {
        if (this.manualValue > this.max) {
          this.manualValue = this.max;
        }
        this.value = this.manualValue;
        this.rangeValue = this.mapValueToRangeValue(this.value);
        this.setValue(true);
      }
    }
    this.editable = !this.editable;
  }

  get displayedValue(): any {
    return this.value % 1 > 0 ? this.value.toFixed(1) : this.value;
  }

  mapValueToRangeValue(value: number): number {
    this.distributions = this.steps.length;
    this.discretePoints = Math.ceil(
      (this.max - this.min) / this.steps.reduce((total, step) => total + step / this.distributions, 0)
    );
    let tmp = value;
    return this.steps.map((s) => {
        const cnt = Math.min(tmp, Math.round(this.discretePoints / this.distributions) * s);
        tmp = tmp - Math.round(this.discretePoints / this.distributions) * s;
        return cnt > 0 ? cnt : 0;
      })
      .map((step, i) => step / this.steps[i])
      .reduce((total, num) => total + num);
  }

  onChange(e): void {
    this.sliderPos = this.slider.handleValue;
  }

}
