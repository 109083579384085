<div #ref>
  <ng-content></ng-content>
</div>
<ng-container *ngIf="!ref.children.length">
  <button class="Polaris-Button float-left"
          [class.Polaris-Button--disabled]="isPrevDisabled | async"
          (click)="prev()">Prev
  </button>
  <button *ngIf="!step.hideNext"
          class="Polaris-Button Polaris-Button--primary float-right"
          [class.Polaris-Button--disabled]="isNextDisabled | async"
          (click)="next()">{{ step.nextLabel ? step.nextLabel : 'Next' }}
  </button>
</ng-container>
