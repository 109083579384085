<input #fileToUpload
       (change)="addFile($event)"
       type="file"
       [attr.multiple]="false"
       accept="image/jpeg,image/png"
       style="display: none;"/>

<div class="row inner">
  <div class="col-7 generator-output">
    <app-artwork-preview
      [previewSrc]="selectedArtworkSrc"
      [fileType]="'image'"
      [file]="file"
      [fileFeedback]="fileFeedback"
      [chosenFormat]="selectedFormat"
      [uploadButton]="true"
      (emitUploadRequest)="selectFile()">
    </app-artwork-preview>
<!--    <div class="upload-btn" (click)="selectFile()">Click to upload artwork</div>-->
  </div>

  <div class="col-5 h-100 overflow-auto">

    <app-format-specs
      *ngIf="specs"
      [format]="format"
      [data]="formatSpecs"
      [contentType]="contentType(format)">
    </app-format-specs>

    <ng-container *ngIf="this.layout && this.layout?.texts && this.layout.texts.length">
      <ng-container *ngFor="let text of this.layout.texts">
        <h4>{{ text.title }}</h4>
        <div class="form-group mb-3">
          <textarea class="form-control"
                [(ngModel)]="data[text.field].value"
                rows="4"
                required>
          </textarea>
        </div>
      </ng-container>

    </ng-container>

<!--    <h4>Font size</h4>-->
<!--    <fc-slider-->
<!--      [value]="data.desc.fontSize"-->
<!--      [min]="50"-->
<!--      [max]="500"-->
<!--      (updateFromSlider)="updatedFontSize($event)">-->
<!--    </fc-slider>-->

<!--    <button class="Polaris-Button pull-right mt-5" (click)="refreshArtwork()">Generate</button>-->

<!--    <div class="upload-btn" (click)="selectFile()">Click to upload artwork</div>-->

  </div>

  <app-spinner *ngIf="processing"></app-spinner>

</div>



