import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PurchaseService } from '../../services/purchase.service';
import { IPurchasePackage } from '../../app.model';
import { takeUntil, tap } from 'rxjs/operators';
import { ShopifyService } from '../../services/shopify.service';
import { CampaignService } from '../../services/campaign.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  public processing = false;

  selected: number = null;
  packages: IPurchasePackage[];

  constructor(
    private purchaseSvc: PurchaseService,
    private shopifySvc: ShopifyService,
    private campaignSvc: CampaignService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.purchaseSvc.packages.subscribe(
      data => this.packages = data
    );
  }

  select(index): void {
    this.selected = index;
  }

  purchase(): void {
    if (this.selected !== null && this.packages[this.selected]) {
      this.processing = true;
      const campaignId = this.campaignSvc.campaign && this.campaignSvc.campaign.id;
      this.purchaseSvc.makePurchase(
        this.packages[this.selected],
        this.shopifySvc.shop,
        this.shopifySvc.host,
        campaignId
      ).pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            if (data && data.status === 'pending') {
              this.shopifySvc.goTo(data.confirmation_url, true);
            }
          },
          err => {
            this.processing = false;
          }
        );
    }
  }

  prev(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
