import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

const COLOR_RANGES = [
  {
    to: 25,
    color: '#e74c3c',
  },
  {
    to: 50,
    color: '#f39c12',
  },
  {
    to: 100,
    color: '#03F7FF',
  },
];

@Component({
  selector: 'app-estimated-budget',
  templateUrl: './estimated-budget.component.html',
  styleUrls: ['./estimated-budget.component.scss'],
})
export class EstimatedBudgetComponent implements OnInit, OnDestroy, OnChanges {

  @Input() budget: number;
  @Input() spend = 0;

  @ViewChild('circle') circle: ElementRef;

  private _destroy$ = new Subject<any>();

  constructor() {}

  ngOnInit() {
    this.setProgress(true);
  }

  get percent(): number {
    return this.spend * 100 / this.budget;
  }

  get percentStr(): string {
    return `${Math.round(this.percent)}%`;
  }

  get color(): string {
    const range = this.percent <= 100 ? COLOR_RANGES.find(r => this.percent <= r.to) : COLOR_RANGES[2];
    return range.color;
  }

  setProgress(initial = false): void {
    if (this.circle) {
      const radius = this.circle.nativeElement.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      this.circle.nativeElement.style.strokeDasharray = `${circumference} ${circumference}`;
      this.circle.nativeElement.style.strokeDashoffset = `${circumference}`;
      this.circle.nativeElement.style.strokeDashoffset = circumference - (initial ? 0 : this.percent) / 100 * circumference;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.estimatedBudget) {
      setTimeout(() => {
        this.setProgress();
      });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
