export const E_PRODUCTS: TShopifyEntity = 'products';
export const E_SHOP: TShopifyEntity = 'shop';
export const E_LOCATIONS: TShopifyEntity = 'locations';

export type TShopifyEntity = 'products' | 'shop' | 'locations';

export interface IShopifyPageInfo {
  endCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  startCursor?: string;
}

export interface IShopifyPageable {
  pageInfo: IShopifyPageInfo;
}

export interface IShopifyProducts extends IShopifyPageable {
  content: IShopifyProduct[];
}

export interface IShopifyProductVariant {
  edges?: [
    node?: {
      sku?: string
    }
  ];
}

export interface IShopifyProductImage {
  edges?: [
    node?: {
      src?: string;
    }
  ];
}

export interface IShopifyProduct {
  id: number;
  title: string;
  cursor?: string;
  body_html?: string;
  vendor?: string;
  product_type?: string;
  created_at?: string;
  handle?: string;
  updated_at?: Date;
  published_at?: Date;
  status?: string;
  tags?: string;
  variants: IShopifyProductVariant[];
  images: IShopifyProductImage[];
}

export interface IShopifyShop {
  id: number;
  name: string;
  email: string;
  domain: string;
  province: string;
  country: string;
  address1: string;
  zip: string;
  city: string;
  source?: any;
  phone: string;
  latitude?: any;
  longitude?: any;
  primary_locale: string;
  address2: string;
  created_at: Date;
  updated_at: Date;
  country_code: string;
  country_name: string;
  currency: string;
  customer_email: string;
  timezone: string;
  iana_timezone: string;
  shop_owner: string;
  money_format: string;
  money_with_currency_format: string;
  weight_unit: string;
  province_code?: any;
  taxes_included: boolean;
  auto_configure_tax_inclusivity?: any;
  tax_shipping?: any;
  county_taxes: boolean;
  plan_display_name: string;
  plan_name: string;
  has_discounts: boolean;
  has_gift_cards: boolean;
  myshopify_domain: string;
  google_apps_domain?: any;
  google_apps_login_enabled?: any;
  money_in_emails_format: string;
  money_with_currency_in_emails_format: string;
  eligible_for_payments: boolean;
  requires_extra_payments_agreement: boolean;
  password_enabled: boolean;
  has_storefront: boolean;
  eligible_for_card_reader_giveaway: boolean;
  finances: boolean;
  primary_location_id: number;
  cookie_consent_level: string;
  visitor_tracking_consent_preference: string;
  checkout_api_supported: boolean;
  multi_location_enabled: boolean;
  setup_required: boolean;
  pre_launch_enabled: boolean;
  enabled_presentment_currencies: string[];
  force_ssl: boolean;
}

export interface IShopifyLocation {
  id: number;
  name: string;
  address1: string;
  address2?: any;
  city: string;
  zip: string;
  province: string;
  country: string;
  phone: string;
  created_at: Date;
  updated_at: Date;
  country_code: string;
  country_name: string;
  province_code?: any;
  legacy: boolean;
  active: boolean;
  admin_graphql_api_id: string;
  localized_country_name: string;
  localized_province_name?: any;
}


