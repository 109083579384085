
<app-text-search
  [label]="'Product search...'"
  (query)="search($event)">
</app-text-search>

<div class="products">
  <div class="product-item"
       *ngFor="let product of data?.content"
       (click)="selectItem(product)">
    <div class="product-item__image"
         [style.backgroundImage]="'url(' + product.images?.edges[0]?.node?.url + ')'">
    </div>
    <div class="product-item__description">
      {{ product.title }}
    </div>
  </div>
</div>

<div class="Pagination-container"
     *ngIf="data?.pageInfo?.hasNextPage || data?.pageInfo?.hasPreviousPage">
  <nav aria-label="Pagination">
    <div class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented" data-buttongroup-segmented="true">
      <div class="Polaris-ButtonGroup__Item">
        <button id="previousURL"
                class="Polaris-Button Polaris-Button--outline Polaris-Button--iconOnly"
                [class.Polaris-Button--disabled]="!data?.pageInfo?.hasPreviousPage"
                (click)="getProducts(data?.pageInfo?.startCursor, null)"
                aria-label="Previous"
                type="button">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Icon">
              <span class="Polaris-Icon">
                <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden">
                </span>
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M12 16a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414l-4.293 4.293 4.293 4.293a.999.999 0 0 1-.707 1.707z">
                  </path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      </div>
      <div class="Polaris-ButtonGroup__Item">
        <button id="nextURL"
                class="Polaris-Button Polaris-Button--outline Polaris-Button--iconOnly"
                [class.Polaris-Button--disabled]="!data?.pageInfo?.hasNextPage"
                (click)="getProducts(null, data?.pageInfo?.endCursor)"
                aria-label="Next"
                type="button">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Icon">
              <span class="Polaris-Icon">
                <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden">
                </span>
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M8 16a.999.999 0 0 1-.707-1.707l4.293-4.293-4.293-4.293a.999.999 0 1 1 1.414-1.414l5 5a.999.999 0 0 1 0 1.414l-5 5a.997.997 0 0 1-.707.293z">
                  </path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      </div>
    </div>
  </nav>
</div>

<app-spinner *ngIf="processing"></app-spinner>
