import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss']
})
export class TextSearchComponent implements OnInit, OnDestroy {

  @Input() label: string;
  @Input() delay = 1000;
  @Output() query = new EventEmitter();

  private destroy$ = new Subject();
  private emitChanges$ = new Subject();

  queryStr: string = null;

  constructor() {
    this.emitChanges$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.delay),
        tap(() => this.query.emit(this.queryStr))
      ).subscribe();
  }

  ngOnInit(): void {
  }

  clear(): void {
    this.queryStr = null;
    this.query.emit(null);
  }

  triggerChange(): void {
    this.emitChanges$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
