<app-header
  [skipStepper]="true"
  [title]="'Your Campaigns'">
</app-header>
<div class="content">

  <div>
    <button class="Polaris-Button Polaris-Button--primary float-right" routerLink="/main">Create new Campaign...</button>
  </div>

  <div class="Polaris-Card">

    <div class="table-header">
      <app-text-search
        [label]="'Campaign search...'"
        (query)="searchQuery($event)">
      </app-text-search>


      <div style="flex: 0 0 250px">
        <div class="Polaris-ResourceList__FiltersWrapper pl-1 pt-1">
          <div class="Polaris-Labelled--hidden">
            <div class="Polaris-Labelled__LabelWrapper">
              <div class="Polaris-Label"><label id="PolarisSelect8Label" for="PolarisSelect8" class="Polaris-Label__Text">Sort
                by</label></div>
            </div>
            <div class="Polaris-Select">
              <select id="PolarisSelect8" class="Polaris-Select__Input" aria-invalid="false"
                      [(ngModel)]="filterStatus"
                      (change)="search()">
                <option *ngFor="let status of statuses" [value]="status.id">{{ status.name }}</option>
              </select>
              <div class="Polaris-Select__Content" aria-hidden="true"><span
                class="Polaris-Select__InlineLabel">By status</span><span
                class="Polaris-Select__SelectedOption">{{ selectedStatus.name }}</span><span class="Polaris-Select__Icon"><span
                class="Polaris-Icon"><span class="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20"
                                                                                      class="Polaris-Icon__Svg"
                                                                                      focusable="false"
                                                                                      aria-hidden="true">
                      <path
                        d="M7.676 9h4.648c.563 0 .879-.603.53-1.014L10.531 5.24a.708.708 0 0 0-1.062 0L7.145 7.986C6.798 8.397 7.113 9 7.676 9zm4.648 2H7.676c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014z"></path>
                    </svg></span></span></div>
              <div class="Polaris-Select__Backdrop"></div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="Polaris-DataTable">
      <div class="Polaris-DataTable__ScrollContainer">
        <table class="Polaris-DataTable__Table">
          <thead>
          <tr>
            <th data-polaris-header-cell="true"
                class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
                scope="col">Campaign Name
            </th>
            <th data-polaris-header-cell="true"
                class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header"
                scope="col">Status
            </th>
            <th data-polaris-header-cell="true"
                class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header"
                scope="col">SKU Number
            </th>
            <th data-polaris-header-cell="true"
                class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                scope="col">Total Budget
            </th>
            <th data-polaris-header-cell="true"
                class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                scope="col">Budget Spent
            </th>
            <th data-polaris-header-cell="true"
                class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                scope="col">&nbsp;
            </th>
          </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="campaigns.length; else NO_CAMPAIGNS">
              <ng-container *ngFor="let campaign of campaigns; let i = index">
                <tr class="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                  <th
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
                    scope="row">
                    <div class="thumbnail">
                      <img *ngIf="campaign.media_file_thumbnail"
                           [src]="campaign.media_file_thumbnail"
                           [alt]="campaign.name"
                           (click)="open(campaign)"/>
                    </div>
                    <ng-container *ngIf="campaign?.offer_targets?.length && campaign?.offer_targets[0]?.status === 'draft'; else NOT_DRAFT">
                      <a href="#" (click)="openCampaign($event, campaign)">
                        {{ campaign.name.replace('[SHOPIFY] ', '') }}
                      </a>
                    </ng-container>
                    <ng-template #NOT_DRAFT>
                      {{ campaign.name.replace('[SHOPIFY] ', '') }}
                    </ng-template>
                  </th>
                  <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                    <ng-container *ngIf="campaign.offer_targets.length">
                      <span class="status-icon {{ campaign?.offer_targets[0]?.status }}"></span>
                      <span class="text-uppercase">{{ campaign?.offer_targets[0]?.status }}</span>
                    </ng-container>
                  </td>
                  <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                    <a href="#" (click)="goToProduct($event, campaign)">
                      <ng-container *ngIf="campaign?.product?.sku; else NO_SKU">
                        campaign?.product?.sku
                      </ng-container>
                      <ng-template #NO_SKU>
                        <i>No SKU</i>
                      </ng-template>
                    </a>
                  </td>
                  <td
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                    {{ campaign?.demand_budget || 0 | currency:'GBP':'symbol-narrow':'1.2-2' }}</td>
                  <td
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                    {{ campaign?.demand_budget_spent || 0 | currency:'GBP':'symbol-narrow':'1.2-2' }}</td>
                  <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle text-right">
                    <app-download-media-plan
                             *ngIf="campaign?.offer_targets[0]?.status !== 'draft'"
                             [campaign]="campaign"
                             [label]="'Media Plan'"
                             [cls]="'Polaris-Button Polaris-Button--sizeSmall'">
                    </app-download-media-plan>
                  </td>

                </tr>
              </ng-container>
            </ng-container>
            <ng-template #NO_CAMPAIGNS>
              <tr class="Polaris-DataTable__TableRow" *ngIf="!processing">
                <td colspan="5" class="Polaris-DataTable__Cell text-center">No Campaigns Found</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<app-spinner *ngIf="processing"></app-spinner>
