<div class="stepper">

  <ng-container *ngIf="isAuthenticated | async">
    <ng-container *ngIf="skipStepper">
      <div class="step active no-point">{{ title }}</div>
    </ng-container>

    <ng-container *ngIf="!skipStepper">
      <div *ngFor="let step of steps; let i = index"
           class="step"
           [class.active]="(currentStep | async) === step.id">
        {{ step.text }}
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="credits-balance"
     *ngIf="isAuthenticated | async">
  <app-credits></app-credits>
</div>
<div class="fc-logo" (click)="goToYourCampaigns()"></div>
