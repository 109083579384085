import { Component, OnInit } from '@angular/core';
import { ICredentials } from '../../app.model';
import { CUSTOM_TOKEN_AUTH_KEY, DEFAULT_PROFILE_SET, FcAuthService } from '../../services/fc-auth.service';
import { ERROR_CODES } from '@components/signin/signin.const';
import { mergeMap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ShopifyService } from '../../services/shopify.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public processing = false;
  data: ICredentials = {
    email: null,
    password: null,
  };
  error: string = null;
  errorCodes = ERROR_CODES;

  constructor(
    private router: Router,
    private flowCityAuth: FcAuthService,
    private shopifySvc: ShopifyService,
  ) { }

  ngOnInit(): void {
    if (this.shopifySvc.shopEntity) {
      this.data.email = this.shopifySvc.shopEntity.email;
    }
  }

  public signIn(): void {
    this.processing = true;
    this.flowCityAuth.signInJWT(this.data)
      .pipe(
        switchMap(() => this.flowCityAuth.getToken$()),
        switchMap(() => this.flowCityAuth.getSession()),
        mergeMap(() => this.flowCityAuth.completeRegistration()),
      )
      .subscribe(
        data => {
          this.processing = false;
          this.flowCityAuth.goToStartPage();
        },
      err => {
          const error = this.errorCodes.find(e => e.code === err.code);
          this.error = error ? error.message : 'Authentication error';
          this.processing = false;
      });
  }

  public get isFormValid(): boolean {
    return this.data && (this.data.email !== null && this.data.password !== null);
  }

}
