<div #artworkContainer class="artwork-wrapper" id="artworkContainer">
  <div id="added_object" [class.white-panel]="!previewSrc" [ngStyle]="artworkPreviewStyle" style="display: none">

    <div class="circular-progress"
         [class.hidden]="!fileFeedback || !fileFeedback.fileUploading"
         [attr.data-percent]="fileFeedback.filePercent + '%'"
         [ngStyle]="uploadProgressStyle(fileFeedback.filePercent)">
    </div>

    <div *ngIf="!previewSrc || !fileType; else preview" class="artwork-preview-text">
<!--      <span>ARTWORK PREVIEW</span>-->
    </div>

    <ng-template #preview>
        <div *ngIf="isImage; else video" [ngStyle]="imageStyles(null, previewSrc)"></div>
        <ng-template #video>
          <video #videoPlayer *ngIf="isVideo"
                 [src]="previewSrc"
                 (mouseenter)="enterVideo()"
                 (mouseleave)="leaveVideo()">
            Your browser does not support HTML5 video.
          </video>
        </ng-template>
      </ng-template>

    <ng-container *ngIf="file && !(fileFeedback && fileFeedback.fileUploading)">
      <div class="overlay overlay-invalid" *ngIf="file?.thumbnail && !file?.thumbnail_inprogress && !file?.transcode_inprogress && !file?._validResolution"></div>
      <div class="overlay overlay-processing" *ngIf="!file?.thumbnail || file?.thumbnail_inprogress || file?.transcode_inprogress">
        <div class="loading" style="display: block"></div>
      </div>
    </ng-container>

    <div *ngIf="uploadButton && (!fileFeedback?.fileUploading || file?.thumbnail_inprogress || file?.transcode_inprogress)"
         class="upload-button btn"
         (click)="selectFile()"
         [class.overlayed]="previewSrc"
         id="upload-new-button">
      <div class="plus-icon">+</div>
      <div class="action-label">Click to upload artwork</div>
    </div>

  </div>
</div>
