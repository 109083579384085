import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './components/slider/slider.component';
import { ArtworkPreviewComponent } from './components/artwork-preview/artwork-preview.component';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RangeStepSliderComponent } from './components/range-step-slider/range-step-slider.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatSliderModule } from '@angular/material/slider';
import { ThousandSuffixPipe } from './pipes/thousand-suffix.pipe';
import { AvailabilityGraphComponent } from './components/availability-graph/availability-graph.component';
import { EstimatedBudgetComponent } from './components/estimated-budget/estimated-budget.component';
import { TextSearchComponent } from './components/text-search/text-search.component';



@NgModule({
  declarations: [
    SliderComponent,
    ArtworkPreviewComponent,
    SpinnerComponent,
    RangeStepSliderComponent,
    ThousandSuffixPipe,
    AvailabilityGraphComponent,
    EstimatedBudgetComponent,
    TextSearchComponent,
  ],
  imports: [
    CommonModule,
    SliderModule,
    FormsModule,
    SliderModule,
    SliderModule,
    NgxCurrencyModule,
    MatSliderModule
  ],
  exports: [
    SliderComponent,
    ArtworkPreviewComponent,
    SpinnerComponent,
    RangeStepSliderComponent,
    ThousandSuffixPipe,
    AvailabilityGraphComponent,
    EstimatedBudgetComponent,
    TextSearchComponent,
  ]
})
export class SharedModule { }
