import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ShopifyService } from './shopify.service';
import { FcAuthService } from './fc-auth.service';

const URL_BLACKLIST = ['cdn', 'assets', 'storage', 'googleapis'];
const AUTH_HEADER_KEY = 'Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    protected http: HttpClient,
    private shopifyService: ShopifyService,
    private fcAuthService: FcAuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};
    if (URL_BLACKLIST.some(s => request.url.includes(s))) {
      return next.handle(request);
    } else {
      return this.fcAuthService.getToken$()
        .pipe(
          switchMap(idToken => {
            if (idToken) {
              headers[AUTH_HEADER_KEY] = `Bearer ${idToken.token}`;
              request = request.clone({
                withCredentials: true,
                setHeaders: headers
              });
            }
            return next.handle(request);
          })
        );
    }
  }

}
