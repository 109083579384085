import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { FcAuthService } from './fc-auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IArtwork, ICampaign, ICampaignFile } from '../app.model';
import { IEstimatedCampaignData } from '../models/estimator.types';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IScheduleModel } from '../models/schedule.model';
import { omit } from '../shared/helpers';
import {
  CAMPAIGN_IGNORED_FIELDS_ON_SAVE,
  CURRENT_CAMPAIGN_URL_KEY,
  ESTIMATOR_API_VERSION_V3
} from '@const/campaign.const';
import dayjs from 'dayjs';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private format = environment.format;

  private _campaign$ = new BehaviorSubject<any>(null);
  public campaign$ = this._campaign$.asObservable();

  private _schedule$ = new BehaviorSubject<IScheduleModel>(null);
  public schedule$ = this._schedule$.asObservable();

  private _target$ = new BehaviorSubject<any>(null);
  public target$ = this._target$.asObservable();

  private _estimatedCampaign$ = new BehaviorSubject<any>(null);
  public estimatedCampaign$ = this._estimatedCampaign$.asObservable();

  private _artwork$ = new BehaviorSubject<IArtwork>(null);
  public artwork$ = this._artwork$.asObservable();

  private _selectedArtwork: ICampaignFile = null;
  private _selectedArtworkSrc: string;

  constructor(
    private http: HttpClient,
    private fcAuthSvc: FcAuthService,
  ) { }

  get campaign(): ICampaign {
    return this._campaign$.getValue();
  }

  set campaign(campagin: ICampaign) {
    this._campaign$.next({...campagin});
  }

  get schedule(): IScheduleModel {
    return this._schedule$.getValue();
  }

  get target(): any {
    return this._target$.getValue();
  }

  get estimatedCampaign(): any {
    return this._estimatedCampaign$.getValue();
  }

  get formatCode(): string {
    return this.format;
  }

  get audienceCode(): string {
    return environment.audience.code;
  }

  set artwork(data: IArtwork) {
    this._artwork$.next(data);
  }

  get artwork(): IArtwork {
    return this._artwork$.getValue();
  }

  public createCampaign(payload): Observable<ICampaign> {
    const headers = new HttpHeaders({
      'X-Skip-Sync': '1',
    });
    const uri = `${ environment.fcApiUrl }${ this.fcAuthSvc.departmentUri}/offers`;
    return this.http.post<ICampaign>(uri, payload, { headers })
      .pipe(
        tap(data => this._campaign$.next(data)),
      );
  }

  public destroyCampaign(): void {
    if (this.campaign && this.campaign.uri) {
      this.http.delete(`${ environment.fcApiUrl }${ this.campaign.uri }`).subscribe();
      this.clearCampaign();
    }
  }

  public getCampaign(uri: string): Observable<ICampaign> {
    return this.http.get<ICampaign>(`${ environment.fcApiUrl }${ uri }`)
      .pipe(
        tap(data => this._campaign$.next(data)),
        tap(data => data.targets.length > 0 && this._target$.next(data.targets[0])),
        switchMap(() => this.getSchedules()),
        switchMap(() => of(this.campaign))
      );
  }

  public clearCampaign(): void {
    this._campaign$.next(null);
    this._schedule$.next(null);
    this._estimatedCampaign$.next(null);
    this._target$.next(null);
    this._artwork$.next(null);
    this._selectedArtwork = null;
    this._selectedArtworkSrc = null;
  }

  public saveCampaign(campaign: ICampaign): Observable<ICampaign> {
    const src = campaign || this.campaign;
    const body = {
      ...omit(...CAMPAIGN_IGNORED_FIELDS_ON_SAVE)(src),
      duration: src.duration.toString(),
      audience: environment.audience.code,
      offer_variant: this.format,
    };
    return this.http.put<ICampaign>(`${ environment.fcApiUrl }${ this.campaign.uri }`, body);
  }

  public getSchedules(): Observable<IScheduleModel> {
    return this.http.get<IScheduleModel[]>(`${ environment.fcApiUrl }${ this.campaign.uris.Schedules }`)
      .pipe(
        map(schedules => schedules.length ? schedules[0] : null),
        tap(schedule => this._schedule$.next(schedule))
      );
  }

  public saveSchedule(): Observable<IScheduleModel> {
    return this.http.put(`${ environment.fcApiUrl }${ this.schedule.uri }`, this.schedule)
      .pipe(tap(schedule => this._schedule$.next(schedule)));
  }

  public getEstimates(): Observable<IEstimatedCampaignData> {
    const payload = this.getCampaignForEstimation();
    const uri = `${ environment.fcOfferService }${ payload.uri }/estimate`;
    const headers = {
        Accept: ESTIMATOR_API_VERSION_V3,
    };
    return this.http.post<IEstimatedCampaignData>(uri,
        payload,
        {headers: new HttpHeaders(headers)},
        ).pipe(
            tap(data => {
              data.audience_reach_results
                ? data.audience_reach_results.map(el => el.audience_uri = el.audience_uri && `/data/display-groups/${ el.audience_uri.split('/').pop() }`)
                : data.audience_reach_results = [];
              }
            ),
            tap(data => this._estimatedCampaign$.next(data)),
        );
  }

  private getCampaignForEstimation(): IEstimatedCampaignData {
    const data: IEstimatedCampaignData = {
      ...omit(...CAMPAIGN_IGNORED_FIELDS_ON_SAVE)(this.campaign),
      audience: environment.audience.code,
      offer_variant: this.format,
      offer_schedule_bases: [this.schedule]
    };
    return data;
  }

  createOfferTarget(): Observable<any> {
    const payload = {
      targets: [environment.audience.uri],
      status: 'draft',
      auto_publish: true
    };
    return this.http.post<any>(`${ environment.fcApiUrl }${ this.campaign.uris.MultiOfferTargets }`, payload)
      .pipe(
        map(data => data[0]),
        tap(data => this._target$.next(data))
      );
  }

  public setOfferTarget(targets: string[], status: string, reason = null): Observable<any> {
    const uri = `${ environment.fcApiUrl }${ this.campaign.uris.UpdateMultipleOfferTargets}`;
    return this.http.post<any>(uri, {
      targets,
      status,
      rejection_reason: reason,
      auto_publish: true,
    });
  }

  public createArtworksFromFeed(data: IArtwork[]): Observable<ICampaignFile[]> {
    const uri = `${ environment.fcApiUrl }${ this.campaign.uris.CreateFilesFromFeed }`;
    return this.http.post<ICampaignFile[]>(uri, { artworks: data });
  }

  get selectedArtwork(): ICampaignFile {
    return this._selectedArtwork;
  }

  set selectedArtwork(artwork: ICampaignFile) {
    this._selectedArtwork = artwork;
  }

  set selectedArtworkSrc(val: string) {
    this._selectedArtworkSrc = val;
  }

  get selectedArtworkSrc(): string {
    return this._selectedArtworkSrc;
  }

  getCampaigns(params: HttpParams, uri= null): Observable<ICampaign[]> {
    const baseUri = `/data/sql-search/offers/normal`;
    return this.http.get<ICampaign[]>(`${ environment.fcApiUrl }${ baseUri }`, { params })
      .pipe(
        map(data => {
          data['objects'] = data['objects'].map(d => ({
            ...d,
            schedule_start: Math.min(...d.offer_schedules.map(sched => dayjs(sched.schedule_start).valueOf())),
            schedule_stop: Math.max(...d.offer_schedules.map(sched => dayjs(sched.schedule_stop).valueOf())),
            weekdays: [...new Set(...d.offer_schedules.map(sched => sched.weekdays))],
            product: this.parseProductData(d),
          }));
          return data;
        }
      )
    );
  }

  private parseProductData(campaign: ICampaign): any {
    try {
      return campaign.text1 ? JSON.parse(campaign.text1) : {};
    } catch (e) {
      return {};
    }
  }

}
