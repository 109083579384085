export const SUBMITTED_STATUS = 'submitted';

export const ESTIMATOR_API_VERSION_V3 = 'application/vnd.com.boldmind.flowcity-offer.v3+json';

export const CAMPAIGN_IGNORED_FIELDS_ON_SAVE = [
  'multi_offer_artworks',
];

export const CURRENT_CAMPAIGN_URL_KEY = 'campaignUrl';

export const statuses = [
  {
    id: 'all',
    name: 'All'
  },
  {
    id: 'submitted',
    name: 'Submitted'
  },
  {
    id: 'online',
    name: 'Online'
  },
  {
    id: 'delivered',
    name: 'Delivered'
  },
  {
    id: 'accepted',
    name: 'Accepted'
  },
  {
    id: 'rejected',
    name: 'Rejected'
  },
  {
    id: 'draft',
    name: 'Draft'
  },
];
