export const STEPS = [
  {
    id: 0,
    text: 'Choose your product',
    footer: true,
    hideNext: true,
  },
  {
    id: 1,
    text: 'Create artwork',
    footer: true,
  },
  {
    id: 2,
    text: 'Campaign details',
    footer: true,
  },
  {
    id: 3,
    text: 'Campaign summary',
    footer: true,
    nextLabel: 'Publish',
  }
];
