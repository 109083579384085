import { Component, OnDestroy, OnInit } from '@angular/core';
import { FcAuthService } from '../../services/fc-auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  credits: number = null;

  constructor(
    private fcAuthSvc: FcAuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fcAuthSvc.currentBalance$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => this.credits = data);
    //setTimeout(() => this.credits = this.fcAuthSvc.company.credits);
  }

  purchase(): void {
    this.router.navigateByUrl('/purchase');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
