export const environment = {
  envName: 'production',
  localDev: false,
  production: true,
  apiUrl: '/api',
  appUrl: 'https://shop.flow.city',
  fcApp: 'flow-ads.appspot.com',
  fcAppUrl: 'https://app.flow.city',
  fcApiUrl: 'https://flow-ads.appspot.com',
  fcAuthService: 'https://auth.flow.city',
  fcOfferService: 'https://offer.flow.city',
  fcReportingService: 'https://report.flow.city',
  firebase: {
    apiKey: 'AIzaSyAFCKZ_RrA77A3hw-43iXe-1d-f0lKLBqA',
    authDomain: 'flow-ads.firebaseapp.com',
  },
  shopifyApiKey: 'c2ffd24645e5445da2422ab038643c2f',
  bugsnagApiKey: 'a7f837851ae9d9e2f28698c770a958e2',
  mapsApiKey: 'AIzaSyCFvujdonUFvzqhHygallawnLompguuRqg',
  audience: {
    code: 'uk_rtb',
    uri: '/data/display-groups/6697890765864960'
  },
  format: 'd6_1080x1920'
};
