import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICampaign } from '../../app.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadMediaPlanService {

  constructor(
    private http: HttpClient,
  ) {
  }

  private static getFileName(response): string {
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
    if (response.headers.get('content-disposition') !== null) {
      return regex.exec(response.headers.get('content-disposition'))[1];
    } else {
      return 'CampaignScreenReport.xlsx';
    }
  }

  public downloadScreenReport(offer: ICampaign = null): Observable<any> {
    return new Observable(subscriber => {
      const uri = `${environment.fcOfferService}${offer.uri}/media-plan-xlsx?_t=${Date.now()}`;
      this.http.head(uri).subscribe(
        () => {
          this.http.get(uri, {observe: 'response' as 'body', responseType: 'blob' as 'json'})
            .subscribe(
              (response: any) => {
                const dataType = response.body.type;
                const binaryData = [];
                const filename = DownloadMediaPlanService.getFileName(response);
                binaryData.push(response.body);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                if (filename) {
                  downloadLink.setAttribute('download', filename);
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                subscriber.next(true);
                subscriber.complete();
              });
        },
        () => subscriber.error()
      );
    });
  }

}
