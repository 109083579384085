import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
// import createApp from '@shopify/app-bridge';
// import { getSessionToken } from '@shopify/app-bridge-utils';
// import { Redirect, Toast } from '@shopify/app-bridge/actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { E_PRODUCTS, IShopifyProduct, IShopifyProducts, IShopifyShop, TShopifyEntity } from '../models/shopify.model';
import { environment } from '@env/environment';
import { MOCK_DATA } from '../shared/mock';

export const GET_SHOP_URL = '/shop';
export const ITEMS_PER_PAGE = 20;

declare var shopifyApp;
declare var Redirect;
declare var Toast;

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {

  app: any;
  token: string;
  shop: string;
  host: string;
  shopEntity: IShopifyShop;
  redirect: any;

  constructor(
    private http: HttpClient,
  ) {
    this.app = shopifyApp;
    console.log('app', this.app);
  }

  getApp(): any {
    return this.app;
  }

  shopifyInit(host, shop): Observable<any> {
    // this.app = createApp({
    //   apiKey: env.shopifyApiKey,
    //   host
    // });
    this.redirect = Redirect.create(this.app);
    return this.getToken()
      .pipe(
        switchMap(() => this.getShop(shop)),
        tap(data => this.shopEntity = data)
      );
  }

  goTo(url: string, remote = false): void {
    if (this.app) {
      const type = remote ? Redirect.Action.REMOTE : Redirect.Action.APP;
      this.redirect.dispatch(type, url);
    } else {
      console.log('REDIRECT', url);
    }
  }

  getToken(): Observable<string> {
    //return from(getSessionToken(this.app));
    return of(null);
  }

  getShop(shop): Observable<IShopifyShop> {
    return this.http.get<IShopifyShop>(`${ environment.apiUrl }${ GET_SHOP_URL }?shop=${ shop }`);
  }

  getNodes(node: any): any {
    return node.edges ? node.edges.map(edge => edge.node) : [];
  }

  showToast(message: string, isError = false, duration = 5000): void {
    if (this.app) {
      const toastOptions = {message, duration, isError};
      const toastNotice = Toast.create(this.app, toastOptions);
      toastNotice.dispatch(Toast.Action.SHOW);
    } else {
      console.log('TOAST', message);
    }
  }

  goToProduct(id: number): void {
    if (this.app) {
      this.redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
        name: Redirect.ResourceType.Product,
        resource: {
          id: id.toString()
        },
      });
    } else {
      console.log('REDIRECT TO PRODUCT', id);
    }
  }

  query(entity: TShopifyEntity = E_PRODUCTS, query = {}): Observable<any> {
    const url = `${ environment.apiUrl }/query/${ entity }`;
    if (environment.localDev) {
      return of(MOCK_DATA);
    }
    return this.http.post(url, query);
  }

  queryProducts(query: string = null, prev: string = null, next: string = null): Observable<IShopifyProducts> {
    const type = !prev && !next ? 'first' : !prev && next ? 'first' : 'last';
    const queryString = `{
      products(${ type }: ${ ITEMS_PER_PAGE }${ query ? ', query: "' + query + '"' : '' }${ prev ? ', before: "' + prev + '"' : '' }${ next ? ', after: "' + next + '"' : '' }) {
        edges {
          cursor
          node {
            title
            id
            vendor
            images(first: 1) {
              edges {
                node {
                  url
                }
              }
            }
            variants(first: 1) {
              edges {
                node {
                  sku
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }`;
    const url = `${ environment.apiUrl }/query/${ E_PRODUCTS }`;
    if (environment.localDev) {
      return of(MOCK_DATA);
    }

    return this.http.post<any>(url, {queryString: JSON.stringify(queryString)})
      .pipe(
        map(prods => ({
            content: prods.data.products.edges,
            pageInfo: prods.data.products.pageInfo,
        })),
        map(d => ({
          ...d,
          content: d.content.map(prod => ({
            cursor: prod.cursor,
            ...prod.node
          }))
        })),
      );
  }


}
// let products = data.data.products.edges;
// products = products.map(prod => ({
//    cursor: prod.cursor,
//    ...prod.node
// }));
