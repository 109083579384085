import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { IAvailabilityData, IAvailabilityGraphData } from './availability.model';
import dayjs from 'dayjs';


const DUMMY_DATA = '/assets/json/availability.json';

const defaultDay = {
  impressions: 0,
};

@Injectable({
  providedIn: 'root',
})
export class AvailabilityHelperService {

  private _data = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
  ) { }

  get data(): IAvailabilityData {
    return this._data.getValue();
  }

  set data(data: IAvailabilityData) {
    this._data.next(data);
  }

  loadData(campaign): Observable<IAvailabilityData> {
    const uri = `${ env.fcApiUrl }${ campaign.uris.Availability}`;
    return this.http.get<IAvailabilityData>(uri)
      .pipe(tap(data => this.data = data));
  }

  private getDayKey(date, idx): string {
    return dayjs(date).clone().add(idx, 'days').format('YYYY-MM-DD');
  }

  parse(): IAvailabilityGraphData {
    const output: IAvailabilityGraphData = {};
    const startDate = dayjs(this.data.summary.startDate);
    for (let i = 0; i < this.data.summary.days; i++) {
      output[this.getDayKey(startDate, i)] = {...defaultDay};
    }

    this.data.results.map(result => {
      const days = dayjs(result.endDate).diff(dayjs(result.startDate), 'days') + 1;
      for (let i = 0; i < days; i++) {
        if (output[this.getDayKey(result.startDate, i)]) {
          output[this.getDayKey(result.startDate, i)].impressions += Math.round(Number(result.allAdults));
        }
      }
    });
    console.log(output);
    return output;
  }

}
