<div class="row inner">
  <div class="col-7 generator-output">
    <app-artwork-preview
      [previewSrc]="previewUrl"
      [fileType]="'image'"
      [chosenFormat]="selectedFormat"
      [uploadButton]="false">
    </app-artwork-preview>
  </div>
  <div class="col-5 h-100 overflow-auto">

    <h4 class="mt-5 mb-2">Summary</h4>

    <ng-container *ngIf="campaignSubmitted">
      <div class="box mt-5 mb-2 success">
        <p>Your campaign has been successfully submitted for approval. You will be notified by email once it is approved or rejected.<br>Note that this process can take up to 3 business days.</p>
        <p>You can always manage your campaigns in Flow City application.</p>
      </div>
    </ng-container>

    <div class="box mt-5 mb-2 error" *ngIf="notEnoughCredits && !campaignSubmitted">
      <p><b>Congrats!</b> Your campaign is almost ready - now choose one of media packages to go live.</p>
      <button class="Polaris-Button" (click)="purchase()">Choose Package</button>
    </div>

    <app-estimated-budget
          [budget]="estimatedCampaign.budget"
          [spend]="estimate.demand_spend">
    </app-estimated-budget>
    <app-download-media-plan class="float-right"
                             [campaign]="campaign">
    </app-download-media-plan>
    <p class="font-weight-light">Your campaign will reach <b><span>{{estimate.reach | thousandSuffix}}</span></b> people in {{ estimate.number }} location{{ estimate.number > 1 ? 's' : '' }}.
    <p class="font-weight-light">It will run for <b><span>{{ estimatedCampaign.duration }}</span> day{{ estimatedCampaign.duration > 1 ? 's' : '' }}</b>&nbsp;<span [innerHTML]="scheduleDesc"></span></p>

    <h4 class="mt-5 mb-5 mb-2 text-center">Campaign Availability</h4>
    <app-availability-graph [campaign]="campaign"></app-availability-graph>

    <ng-container *ngIf="!campaignSubmitted">
      <div class="box mt-5 mb-2">
        <p>You can adjust campaign details on the previous screen.</p>
      </div>
    </ng-container>

  </div>

  <app-spinner *ngIf="processing"></app-spinner>

</div>
