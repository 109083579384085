import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { FcAuthService } from '../../services/fc-auth.service';
import { ICampaign, ICampaignFile } from '../../app.model';
import { HttpParams } from '@angular/common/http';
import { CampaignService } from '../../services/campaign.service';
import { ShopifyService } from '../../services/shopify.service';
import { statuses } from '@const/campaign.const';
import { FileService } from '../../services/file.service';
import { ControlService } from '../../services/control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import dayjs from 'dayjs';

const DEFAULT_IMG = 'https://cdn.flow.city/images/fc_logo.png';

@Component({
  selector: 'app-your-campaigns',
  templateUrl: './your-campaigns.component.html',
  styleUrls: ['./your-campaigns.component.scss']
})
export class YourCampaignsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  public processing = false;

  public statuses = statuses;
  public filterStatus: any = statuses[0].id;
  public searchString: string = null;
  public campaigns: ICampaign[] = [];
  private nextUri: string = null;
  private lastPage = false;

  constructor(
    private fcAuthSvc: FcAuthService,
    private campaignSvc: CampaignService,
    private shopifySvc: ShopifyService,
    private fileSvc: FileService,
    private controlSvc: ControlService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.fcAuthSvc.getCurrentBalance()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.controlSvc.currentStep = 0;
    this.campaignSvc.clearCampaign();
    this.fileSvc.reset();

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        const campaignUrl = this.fcAuthSvc.getCampaignRedirectUrl(params.id);
        this.loadCampaign(campaignUrl);
      } else {
        this.getObjects(true);
      }
    });
  }

  private loadCampaign(uri: string, noBudget = false, inPast = false): void {
    this.processing = true;
    this.campaignSvc.getCampaign(uri)
        .pipe(
          switchMap(() => !noBudget && !inPast ? this.campaignSvc.getEstimates() : of({})),
        ).subscribe(
        () => {
          this.fileSvc.init(this.campaignSvc.campaign)
            .pipe(
              tap(() => this.campaignSvc.selectedArtwork = this.fileSvc.campaignFiles.length ? this.fileSvc.campaignFiles[0] : null),
              switchMap(() => this.fileSvc.campaignFiles.length ? this.fileSrc : of(null))
            ).subscribe(
            url => {
              if (url) {
                this.campaignSvc.selectedArtworkSrc = url;
                this.controlSvc.currentStep = (noBudget || inPast) ? 2 : 3;
              } else {
                this.controlSvc.currentStep = 1;
              }
              this.router.navigateByUrl('/main');
            }
          );
        },
      () => this.processing = false,
      );
  }

  searchQuery(val: string): void {
    this.searchString = val;
    this.search();
  }

  private getObjects(reset = false): void {
    if (this.processing) {
      return;
    }

    if (reset) {
      this.nextUri = null;
      this.lastPage = false;
      this.campaigns = [];
    }

    if (this.lastPage) {
      return;
    }

    let params = new HttpParams();
    if (!this.nextUri) {

      const query = {};
      params = params.append('limit', '24');
      params = params.append('objects', '1');

      if (this.filterStatus && this.filterStatus !== 'all') {
        params = params.append('status', this.filterStatus);
      }

      if (this.searchString) {
        query['_global'] = this.searchString;
      }

      if (Object.keys(query).length) {
        params = params.append('query', JSON.stringify(query));
      }
    }

    this.processing = true;
    this.campaignSvc.getCampaigns(params, this.nextUri ||  null)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(
        (data) => {
          const results = data['objects'];
          this.campaigns = [...this.campaigns, ...results];
          this.nextUri = data['next_uri'];
          if (!this.nextUri) {
            this.lastPage = true;
          }
          this.processing = false;
          console.log(this.campaigns);
        },
        (err) => {
          console.log(err);
          this.processing = false;
        }
      );
  }

  goToProduct(event, campaign): void {
    event && event.preventDefault();
    if (campaign.product && campaign.product.product_id) {
      this.shopifySvc.goToProduct(campaign.product.product_id);
    }
  }

  get selectedStatus(): any {
    return this.statuses.find(s => s.id === this.filterStatus);
  }

  search(): void {
    this.getObjects(true);
  }

  get fileSrc(): Observable<string> {
    return new Observable(subscriber => {
      if (this.selectedArtwork) {
        this.fileSvc.getArtworkSrcAsync(this.selectedArtwork)
           .subscribe(
           redirectUrl => {
             subscriber.next(redirectUrl);
             subscriber.complete();
           });
       }
    });
  }

  get selectedArtwork(): ICampaignFile {
    return this.campaignSvc.selectedArtwork;
  }

  openCampaign(event: any, campaign: ICampaign): void {
    event && event.preventDefault();
    const noBudget = Number(campaign.budget) === 0;
    const inPast = dayjs(campaign.schedule_start) <= dayjs();
    this.loadCampaign(campaign.uri, noBudget, inPast);
  }

  open(campaign: ICampaign): void {
    const album: Array<any> = [];
    album.push(
      {
        src: campaign.media_file_thumbnail || DEFAULT_IMG,
        thumb: campaign.media_file_thumbnail || DEFAULT_IMG,
        caption: campaign.name.replace('[SHOPIFY] ', ''),
      }
    );
    this.lightbox.open(album, 0);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
