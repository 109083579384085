<div style="max-width: 500px;">
  <div class="Polaris-ResourceList__ResourceListWrapper">
    <div class="Polaris-ResourceList__FiltersWrapper pl-1 pt-1">
      <div class="Polaris-Filters">
        <div class="Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer" aria-hidden="true">
          <div data-key="accountStatus">
            <button class="Polaris-Button" type="button">
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Text">Account status</span>
                <span class="Polaris-Button__Icon">
                  <div class="">
                    <span class="Polaris-Icon">
                      <span
                        class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden">
                      </span>
                      <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                        <path
                          d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z">
                        </path>
                      </svg>
                    </span>
                  </div>
                </span>
              </span>
            </button>
          </div>
          <div data-key="taggedWith">
            <button class="Polaris-Button" type="button">
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Text">Tagged with</span>
                <span class="Polaris-Button__Icon">
                  <div class="">
                    <span class="Polaris-Icon">
                      <span
                        class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden">
                      </span>
                      <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                        <path
                          d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z">
                        </path>
                      </svg>
                    </span>
                  </div>
                </span>
              </span>
            </button>
          </div>
        </div>
        <div class="Polaris-Filters-ConnectedFilterControl__Wrapper">
          <div class="Polaris-Filters-ConnectedFilterControl Polaris-Filters-ConnectedFilterControl--right">
            <div class="Polaris-Filters-ConnectedFilterControl__CenterContainer">
              <div class="Polaris-Filters-ConnectedFilterControl__Item">
                <div class="Polaris-Labelled--hidden">
                  <div class="Polaris-Labelled__LabelWrapper">
                    <div class="Polaris-Label">
                      <label id="PolarisTextField1Label" for="query" class="Polaris-Label__Text">
                        <span class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">{{ label }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="Polaris-Connected">
                    <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                      <div class="Polaris-TextField">
                        <div class="Polaris-TextField__Prefix" id="PolarisTextField1-Prefix">
                          <span class="Polaris-Filters__SearchIcon">
                            <span class="Polaris-Icon">
                              <span
                                class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden">
                              </span>
                              <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                <path
                                  d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm9.707 4.293-4.82-4.82a5.968 5.968 0 0 0 1.113-3.473 6 6 0 0 0-12 0 6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414z">
                                </path>
                              </svg>
                            </span>
                          </span>
                        </div>
                        <input id="query" [placeholder]="label" [(ngModel)]="queryStr"
                               (keyup)="triggerChange()"
                               autocomplete="off"
                               class="Polaris-TextField__Input Polaris-TextField__Input--hasClearButton" type="text"
                               aria-labelledby="PolarisTextField1Label PolarisTextField1-Prefix" aria-invalid="false"
                               value="">
                        <button type="button" class="Polaris-TextField__ClearButton"
                                (click)="clear()">
                          <span class="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                            <span
                              class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--regular Polaris-Text--visuallyHidden">
                            </span>
                            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                              <path fill-rule="evenodd"
                                    d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-2.293 4.293a1 1 0 0 0-1.414 1.414l2.293 2.293-2.293 2.293a1 1 0 1 0 1.414 1.414l2.293-2.293 2.293 2.293a1 1 0 1 0 1.414-1.414l-2.293-2.293 2.293-2.293a1 1 0 0 0-1.414-1.414l-2.293 2.293-2.293-2.293z">
                              </path>
                            </svg>
                          </span>
                        </button>
                        <div class="Polaris-TextField__Backdrop">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
