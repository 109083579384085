import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlService } from '../../services/control.service';
import { CampaignService } from '../../services/campaign.service';
import { SUBMITTED_STATUS } from '@const/campaign.const';
import { skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IFormatEstimation } from '../../models/estimator.types';
import { ICampaign } from '../../app.model';
import { IScheduleModel } from '../../models/schedule.model';
import { ShopifyService } from '../../services/shopify.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { FcAuthService } from '../../services/fc-auth.service';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.scss']
})
export class CampaignSummaryComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public processing = false;

  estimate: IFormatEstimation;
  notEnoughCredits = false;

  campaignSubmitted = false;

  constructor(
    private controlSvc: ControlService,
    private campaignSvc: CampaignService,
    private shopifySvc: ShopifyService,
    private router: Router,
    private fcAuthSvc: FcAuthService,
    private fileSvc: FileService,
  ) { }

  ngOnInit(): void {

    this.estimate = this.getEstimate();
    this.saveCampaign();
    this.controlSvc.nextDisabled = true;

    this.controlSvc.next$
      .pipe(
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.campaignSubmitted) {
          this.publishCampaign();
        } else {
          this.campaignSvc.clearCampaign();
          this.fileSvc.reset();
          this.controlSvc.customNextStepLabel = null;
          this.controlSvc.currentStep = 0;
          this.router.navigateByUrl('/your-campaigns');
        }
      });

    this.fcAuthSvc.getCurrentBalance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.notEnoughCredits = data.remaining_budget < (parseInt(this.campaignSvc.campaign.budget, 10) || 0);
        this.controlSvc.nextDisabled = this.notEnoughCredits;
        if (this.notEnoughCredits) {
          this.shopifySvc.showToast(
            'You don\'t have enough funds to play this Campaign.',
            true,
            10000
          );
        }
      });
  }

  publishCampaign(): void {
    this.processing = true;
    this.campaignSvc.setOfferTarget([this.campaignSvc.target.uri], SUBMITTED_STATUS)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.processing = false;
        this.shopifySvc.showToast(
          'Your Campaign has been submitted for an approval',
          false,
          10000
        );
        this.campaignSubmitted = true;
        this.controlSvc.prevDisabled = true;
        this.controlSvc.customNextStepLabel = 'Close';
      },
        () => this.processing = false,);
  }

  get estimatedCampaign(): any {
    return this.campaignSvc.estimatedCampaign;
  }

  getEstimate(): IFormatEstimation {
    return this.estimatedCampaign.audience_reach_results.find(a => a.selected).formats.find(f => f.selected);
  }

  get schedule(): IScheduleModel {
    return this.campaignSvc.schedule;
  }

  get scheduleDesc(): string {
    return this.schedule.description.replace('all day long', '').replace('<br>', ' ');
  }

  get campaign(): ICampaign {
    return this.campaignSvc.campaign;
  }

  goToApp(): void {
    window.open(`${environment.fcAppUrl}`, '_blank');
  }

  get previewUrl(): string {
    return this.campaignSvc.selectedArtworkSrc;
  }

  get selectedFormat() {
    return this.controlSvc.selectedFormat;
  }

  purchase(): void {
    this.router.navigateByUrl('/purchase');
  }

  saveCampaign(): void {
    const campaign = this.campaign;
    campaign.num_of_screens = this.estimate.number || 0;
    campaign.reach = this.estimate.reach || 0;
    this.campaignSvc.saveCampaign(campaign)
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
