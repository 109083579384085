import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import { HeaderComponent } from '@components/header/header.component';
import { ProductSelectorComponent } from '@components/product-selector/product-selector.component';
import { ArtworkGeneratorComponent } from '@components/artwork-generator/artwork-generator.component';
import { CampaignDetailsComponent } from '@components/campaign-details/campaign-details.component';
import { CampaignSummaryComponent } from '@components/campaign-summary/campaign-summary.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@env/environment';
import { FooterComponent } from '@components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SigninComponent } from '@components/signin/signin.component';
import { SignupComponent } from '@components/signup/signup.component';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@components/main/main.component';
import { CampaignLocationModule } from '@module/campaign-location/campaign-location.module';
import { WelcomeComponent } from '@components/welcome/welcome.component';
import { DownloadMediaPlanModule } from '@module/download-media-plan/download-media-plan.module';
import { CreativeSpecsModule } from '@module/creative-specs/creative-specs.module';
import { PurchaseComponent } from '@components/purchase/purchase.component';
import { CreditsComponent } from '@components/credits/credits.component';
import Bugsnag from '@bugsnag/js';
import { environment as env } from '../environments/environment';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { SignoutComponent } from '@components/signout/signout.component';
import { YourCampaignsComponent } from '@components/your-campaigns/your-campaigns.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material-dayjs';

Bugsnag.start({
  apiKey: env.bugsnagApiKey,
  releaseStage: env.envName,
});

export function errorHandlerFactory(): any {
  return new BugsnagErrorHandler();
}

const routes: Routes = [
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'main',
    component: MainComponent,
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'purchase',
    component: PurchaseComponent,
  },
  {
    path: 'signout',
    component: SignoutComponent,
  },
  {
    path: 'your-campaigns',
    component: YourCampaignsComponent,
  },
  {
    path: 'your-campaigns/:id',
    component: YourCampaignsComponent,
  },
  {
    path: 'your-campaigns/:id/:purchaseId',
    component: YourCampaignsComponent,
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProductSelectorComponent,
    ArtworkGeneratorComponent,
    CampaignDetailsComponent,
    CampaignSummaryComponent,
    FooterComponent,
    SigninComponent,
    SignupComponent,
    MainComponent,
    WelcomeComponent,
    PurchaseComponent,
    CreditsComponent,
    SignoutComponent,
    YourCampaignsComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    NgxDaterangepickerMd.forRoot({
      applyLabel: 'OK',
      firstDay: 3
    }),
    CampaignLocationModule,
    DownloadMediaPlanModule,
    CreativeSpecsModule,
    LightboxModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
