import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


const CREATIVE_SPECS_URI = '/data/creative-specs';
const BASE_ICON_PATH = 'http://cdn.flow.city/formats';


@Injectable()
export class CreativeSpecsService {

  private readonly _creativeSpecs = new BehaviorSubject<any>(null);
  private creativeSpecs$ = this._creativeSpecs.asObservable();

  constructor(
    private http: HttpClient,
  ) {
  }

  getSpecs(): any {
    if (this._creativeSpecs.getValue() == null) {
      return this.http.get(`${env.fcApiUrl}${CREATIVE_SPECS_URI}`)
        .pipe(
          map(data => {
            for (const [key, value] of Object.entries(data)) {
              let idx = 0;
              data[key] = value.reduce((r, a) => {
                const format = a.FORMAT.trim();
                idx = Object.entries(r).length > 0 ? ({}).hasOwnProperty.call(r, `${idx}_${format}`) ? idx : idx + 1 : 0;
                r[`${idx}_${format}`] = r[`${idx}_${format}`] || [];
                r[`${idx}_${format}`].push(a);
                r[`${idx}_${format}`].sort((a, b) => parseInt(a['Width'], 10) < parseInt(b['Width'], 10) ? 1 : -1);
                return r;
                }, Object.create(null));
              }
            return data;
          }),
          tap(data => this._creativeSpecs.next(data)),
        );
    } else {
      return this.creativeSpecs$;
    }
  }

  getIcon(format: string): string {
    return `${BASE_ICON_PATH}/${format.split('_')[0]}.svg`;
  }

}
