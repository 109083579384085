import { Component, OnInit } from '@angular/core';
import { ICredentials } from '../../app.model';
import { ERROR_CODES } from '@components/signin/signin.const';
import { DEFAULT_PROFILE_SET, FcAuthService } from '../../services/fc-auth.service';
import { ShopifyService } from '../../services/shopify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public processing = false;
  data: ICredentials = {
    email: null,
    password: null,
  };
  error: string = null;
  errorCodes = ERROR_CODES;

  constructor(
    private router: Router,
    private flowCityAuth: FcAuthService,
    private shopifySvc: ShopifyService,
  ) { }

  ngOnInit(): void {
    if (history.state && history.state.error) {
      this.error = history.state.example;
    }
  }

  public signUp(): void {
    this.processing = true;
    this.flowCityAuth.registerCompany({
      name: this.data.name,
      email: this.data.email,
      password: this.data.password,
      shop: this.shopifySvc.shopEntity.domain,
      profiles_set: DEFAULT_PROFILE_SET,
    }).subscribe(
      () => {
        this.processing = false;
        this.router.navigateByUrl('/welcome');
        },
        err => {
        this.error = err.reason;
        this.processing = false;
      });
  }

  public get isFormValid(): boolean {
    return this.data && (this.data.name !== null && this.data.email !== null && this.data.password !== null);
  }

}
