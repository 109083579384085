export const FORMATS = {
  portrait: {
    artwork_spec: {
      resolution: {
        width: 1080,
        height: 1920
      }
    }
  },
  landscape: {
    artwork_spec: {
      resolution: {
        width: 1920,
        height: 1080
      }
    }
  }
};

export const SPECS = {
  '1_Landscape': {
    '0_HDTV': [
      {
        'FORMAT': 'HDTV',
        'image': {
          'File Size': '',
          'Supported Formats': '',
          'Copy Length': ''
        },
        'Height': '1080',
        'Width': '1920',
        'Media Owner': 'Zoom Media',
        'video': {
          'File Size': '5MB',
          'Sound': 'AAC - MPEG4 @ 320 kbps',
          'Supported Formats': 'H.264/MPEG4 AVC',
          'Frame Rate': '25 FPS',
          'Copy Length': '20s'
        },
        'Location': 'Gym TV',
        'Content Type': 'Video',
        'Rotation': '',
        'Aspect Ratio': '16:9'
      }
    ],
    '1_DIGITAL\nPOSTER GALLERY - Berlin': [
      {
        'FORMAT': 'DIGITAL\nPOSTER GALLERY - Berlin',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '1080',
        'Width': '1920',
        'Media Owner': 'JC Decaux GER',
        'video': {
          'File Size': '2MB',
          'Sound': '',
          'Supported Formats': 'MP4',
          'Frame Rate': '25 FPS',
          'Copy Length': '10s'
        },
        'Location': 'Berlin',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '16:9'
      }
    ],
    '2_DIGITAL\nCITY LIGHT BOARD\n(DCLB) - Berlin, Hamburg': [
      {
        'FORMAT': 'DIGITAL\nCITY LIGHT BOARD\n(DCLB) - Berlin, Hamburg',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '330',
        'Width': '440',
        'Media Owner': 'JC Decaux GER',
        'video': {},
        'Location': 'Berlin, Hamburg',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '4:3'
      }
    ],
    '3_D48': [
      {
        'FORMAT': 'D48',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '576',
        'Width': '1152',
        'Media Owner': 'Smartoutdoor',
        'video': {
          'Supported Formats': '',
          'Copy Length': '10s'
        },
        'Location': '',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      },
      {
        'FORMAT': 'D48',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '288',
        'Width': '576',
        'Media Owner': 'Smartoutdoor',
        'video': {
          'Supported Formats': '',
          'Copy Length': '10s'
        },
        'Location': 'roadside',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      }
    ],
    '4_D48 -Tyne Tunnel': [
      {
        'FORMAT': 'D48 -Tyne Tunnel ',
        'image': {
          'Supported Formats': 'JPEG'
        },
        'Height': '384',
        'Width': '720',
        'Media Owner': 'Smartoutdoor',
        'video': {},
        'Location': 'Tyne Tunnel ',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '15:8'
      }
    ],
    '5_D48 - Tyne Tunnel': [
      {
        'FORMAT': 'D48 - Tyne Tunnel ',
        'image': {
          'Supported Formats': 'JPEG'
        },
        'Height': '320',
        'Width': '600',
        'Media Owner': 'Smartoutdoor',
        'video': {},
        'Location': 'Tyne Tunnel ',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '15:8'
      }
    ],
    '6_D48 - Transvision LIVERPOOL LIME STREET STATION': [
      {
        'FORMAT': 'D48 - Transvision LIVERPOOL LIME STREET STATION',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '480',
        'Width': '960',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '20s'
        },
        'Location': 'LIVERPOOL LIME STREET STATION STN',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      }
    ],
    '7_D48 - LDN Drive/National Drive': [
      {
        'FORMAT': 'D48 - LDN Drive/National Drive',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '306',
        'Width': '612',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'roadsides',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      }
    ],
    '8_D96 - THE WANDSWORTH ROUNDABOUT': [
      {
        'FORMAT': 'D96 - THE WANDSWORTH ROUNDABOUT',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '360',
        'Width': '1404',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'very busy arterial routes THE WANDSWORTH ROUNDABOUT',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '39:10'
      }
    ],
    '9_D96 - THE BARNES LANDMARK , THE EDINBURGH ARCH , WESTERN AVENUE LANDMARKS': [
      {
        'FORMAT': 'D96 - THE BARNES LANDMARK , THE EDINBURGH ARCH , WESTERN AVENUE LANDMARKS',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '324',
        'Width': '1224',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE BARNES LANDMARK THE EDINBURGH ARCH, WESTERN AVENUE LANDMARKS',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '34:9'
      }
    ],
    '10_D96 - THE CROMWELL ROAD DIGITAL': [
      {
        'FORMAT': 'D96 - THE CROMWELL ROAD DIGITAL',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '320',
        'Width': '1216',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE CROMWELL ROAD DIGITAL',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '19:5'
      }
    ],
    '11_AdDryer': [
      {
        'FORMAT': 'AdDryer',
        'image': {
          'File Size': '30MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '1080',
        'Width': '1920',
        'Media Owner': 'Savortex',
        'video': {
          'File Size': '30MB',
          'Sound': 'No',
          'Supported Formats': 'MP4 using H.264 Codec',
          'Frame Rate': '25 FPS',
          'Copy Length': '10s'
        },
        'Location': 'Hand Dryer',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '16:9'
      }
    ]
  },
  '2_Iconic': {
    '0_Iconic -WATERLOO STN - Rail (DIGITAL MOTION)': [
      {
        'FORMAT': 'Iconic -WATERLOO STN - Rail (DIGITAL MOTION)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '480',
        'Width': '6720',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '20s'
        },
        'Location': 'WATERLOO STN, WATERLOO ROAD, WATERLOO MOTION',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '14:1'
      }
    ],
    '1_Iconic - EUSTON STN (DIGITAL MOTION)': [
      {
        'FORMAT': 'Iconic - EUSTON STN (DIGITAL MOTION)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '800',
        'Width': '3200',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '20s'
        },
        'Location': 'EUSTON STN',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '4:1'
      }
    ],
    '2_Iconic - Rail -LIVERPOOL LIME STREET STATION STN (DIGITAL MOTION)': [
      {
        'FORMAT': 'Iconic - Rail -LIVERPOOL LIME STREET STATION STN (DIGITAL MOTION)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '480',
        'Width': '960',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '20s'
        },
        'Location': 'Rail  LIVERPOOL LIME STREET STATION STN ',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '2:1'
      }
    ],
    '3_Iconic - Rail - LIVERPOOL STREET STN , FENCHURCH STREET STN, CITY THAMESLINK STN, CANNON STREET STN, BLACKFRIARS STN  (DIGITAL MOTION)': [
      {
        'FORMAT': 'Iconic - Rail - LIVERPOOL STREET STN , FENCHURCH STREET STN, CITY THAMESLINK STN, CANNON STREET STN, BLACKFRIARS STN  (DIGITAL MOTION)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '240',
        'Width': '960',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '20s'
        },
        'Location': ' Rail - LIVERPOOL STREET STN , FENCHURCH STREET STN, CITY THAMESLINK STN, CANNON STREET STN, BLACKFRIARS STN',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '4:1'
      }
    ],
    '4_Iconic - Rail - LONDON BRIDGE STN(DIGITAL MOTION)': [
      {
        'FORMAT': 'Iconic - Rail - LONDON BRIDGE STN(DIGITAL MOTION)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '400',
        'Width': '800',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '20s'
        },
        'Location': 'LONDON BRIDGE STN',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '2:1'
      }
    ],
    '5_Iconic Landscape - THE KENSINGTON Roadside (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape - THE KENSINGTON Roadside (DIGITAL SPECIAL)',
        'image': {
          'Supported Formats': 'Colour Model: RGB',
          'Copy Length': '12s'
        },
        'Height': '765',
        'Width': '2970',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE KENSINGTON',
        'Content Type': 'Image',
        'Rotation': 'Landscape',
        'Aspect Ratio': '66:17'
      }
    ],
    '6_Iconic Landscape - THE TRAFFORD ARCH , THE SALFORD ARCH - Roadside (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape - THE TRAFFORD ARCH , THE SALFORD ARCH - Roadside (DIGITAL SPECIAL)',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '468',
        'Width': '1836',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE TRAFFORD ARCH , THE SALFORD ARCH',
        'Content Type': 'Image',
        'Rotation': 'Landscape',
        'Aspect Ratio': '51:13'
      }
    ],
    '7_Iconic Landscape - THE CROMWELL ROAD GATEWAY Roadside (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape - THE CROMWELL ROAD GATEWAY Roadside (DIGITAL SPECIAL)',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '30s'
        },
        'Height': '480',
        'Width': '1824',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE CROMWELL ROAD GATEWAY ',
        'Content Type': 'Image',
        'Rotation': 'Landscape',
        'Aspect Ratio': '19:5'
      }
    ],
    '8_Iconic Landscape - Tyne Tunnel  - Roadside (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape - Tyne Tunnel  - Roadside (DIGITAL SPECIAL)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '675',
        'Width': '1680',
        'Media Owner': 'Smartoutdoor',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '10s'
        },
        'Location': 'Tyne Tunnel ',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '112:45'
      }
    ],
    '9_Iconic Landscape - Malls Digital Special, WESTFIELD STRATFORD (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape - Malls Digital Special, WESTFIELD STRATFORD (DIGITAL SPECIAL)',
        'image': {
          'File Size': '',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '675',
        'Width': '1680',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '10s'
        },
        'Location': 'WESTFIELD STRATFORD DIGITAL SPECIAL',
        'Content Type': 'Image & Video',
        'Rotation': 'Landscape',
        'Aspect Ratio': '112:45'
      }
    ],
    '10_Iconic Landscape -THE LUCOZADE LANDMARK OUTBOUND - Roadside (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape -THE LUCOZADE LANDMARK OUTBOUND - Roadside (DIGITAL SPECIAL)',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '30s'
        },
        'Height': '468',
        'Width': '900',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE LUCOZADE LANDMARK OUTBOUND',
        'Content Type': 'Image',
        'Rotation': 'Landscpae',
        'Aspect Ratio': '25:13'
      }
    ],
    '11_Iconic Landscape - THE LUCOZADE LANDMARK INBOUND - Roadside (DIGITAL SPECIAL)': [
      {
        'FORMAT': 'Iconic Landscape - THE LUCOZADE LANDMARK INBOUND - Roadside (DIGITAL SPECIAL)',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '30s'
        },
        'Height': '552',
        'Width': '828',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE LUCOZADE LANDMARK INBOUND',
        'Content Type': 'Image',
        'Rotation': 'Landscpae',
        'Aspect Ratio': '3:2'
      }
    ],
    '12_Iconic Portrait -THE MARYLEBONE TOWER [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -THE MARYLEBONE TOWER [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': ' 30s'
        },
        'Height': '880',
        'Width': '576',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE MARYLEBONE TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '36:55'
      }
    ],
    '13_Iconic Portrait - W8 - THE A40 TOWER - [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - W8 - THE A40 TOWER - [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '30s'
        },
        'Height': '864',
        'Width': '576',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'W8 - THE A40 TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '2:3'
      }
    ],
    '14_Iconic Portrait - THE A3 TOWER - [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - THE A3 TOWER - [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '864',
        'Width': '576',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE A3 TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '2:3'
      }
    ],
    '15_Iconic Portrait - PADDIGTON TOWER- [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - PADDIGTON TOWER- [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '766',
        'Width': '510',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'PADDIGTON TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '255:383'
      }
    ],
    '16_Iconic Portrait -THE STRATFORD TOWER - [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -THE STRATFORD TOWER - [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '737',
        'Width': '504',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE STRATFORD TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '504:737'
      }
    ],
    '17_Iconic Portrait- THE BARNES TOWER, OLD STREET SHOWCASE, WANDSWORTH TOWER EAST, THE TRAFFORD TOWER, W8 - THE WEST CROSS TOWER- [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait- THE BARNES TOWER, OLD STREET SHOWCASE, WANDSWORTH TOWER EAST, THE TRAFFORD TOWER, W8 - THE WEST CROSS TOWER- [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '756',
        'Width': '504',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE BARNES TOWER,OLD STREET SHOWCASE, WANDSWORTH TOWER EAST, W8 - THE WEST CROSS TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '2:3'
      }
    ],
    '18_Iconic Portrait -THE M4 TOWER- [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -THE M4 TOWER- [DIGITAL 450]',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': ' 30s'
        },
        'Height': '960',
        'Width': '480',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE M4 TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '1:2'
      }
    ],
    '19_Iconic Portrait -HOLLAND PARK TOWER [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -HOLLAND PARK TOWER [DIGITAL 450]',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '800',
        'Width': '480',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'HOLLAND PARK TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '3:5'
      }
    ],
    '20_Iconic Portrait -HAMMERSMITH TOWER- [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -HAMMERSMITH TOWER- [DIGITAL 450]',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '768',
        'Width': '480',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'HAMMERSMITH TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '5:8'
      }
    ],
    '21_Iconic Portrait - SW - THE A3 FIN, LEEDS TOWER , SOUTHBANK SE1, WESTERN AVENUE TOWER- [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - SW - THE A3 FIN, LEEDS TOWER , SOUTHBANK SE1, WESTERN AVENUE TOWER- [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '720',
        'Width': '468',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'SW - THE A3 FIN, LEEDS TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '13:20'
      }
    ],
    '22_Iconic Portrait - THE PUTNEY TOWER, THE WESTMINSTER TOWERS, THE MANCHESTER TOWERS, THE BIRMINGHAM TOWERS,THE EDINBURGH TOWERS, THE LIVERPOOL TOWERS, THE SHEFFIELD TOWERS, LEEDS TOWERS -[DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - THE PUTNEY TOWER, THE WESTMINSTER TOWERS, THE MANCHESTER TOWERS, THE BIRMINGHAM TOWERS,THE EDINBURGH TOWERS, THE LIVERPOOL TOWERS, THE SHEFFIELD TOWERS, LEEDS TOWERS -[DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '701',
        'Width': '468',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE PUTNEY TOWER, THE WESTMINSTER TOWERS, THE MANCHESTER TOWERS,THE BIRMINGHAM TOWERS, THE EDINBURGH TOWERS, THE LIVERPOOL TOWERS, THE SHEFFIELD TOWERS, LEEDS TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '468:701'
      }
    ],
    '23_Iconic Portrait - A4 SHOWCASE -[DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - A4 SHOWCASE -[DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '696',
        'Width': '464',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'A4 SHOWCASE',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '2:3'
      }
    ],
    '24_Iconic Portrait - THE M4 TORCH -[DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait - THE M4 TORCH -[DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '656',
        'Width': '448',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE M4 TORCH',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '28:41'
      }
    ],
    '25_Iconic Portrait -GREAT WEST ROAD TOWER- [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -GREAT WEST ROAD TOWER- [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '582',
        'Width': '396',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'GREAT WEST ROAD TOWER',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '66:97'
      }
    ],
    '26_Iconic Portrait -THE SHOREDITCH SHOWCASE - [DIGITAL 450]': [
      {
        'FORMAT': 'Iconic Portrait -THE SHOREDITCH SHOWCASE - [DIGITAL 450] ',
        'image': {
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '593',
        'Width': '396',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': 'THE SHOREDITCH SHOWCASE',
        'Content Type': 'Image',
        'Rotation': 'Portrait',
        'Aspect Ratio': '396:593'
      }
    ],
    '27_MALL VISION -BLUEWATER CENTRE - DARTFORD': [
      {
        'FORMAT': 'MALL VISION -BLUEWATER CENTRE - DARTFORD',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '620',
        'Width': '348',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'BLUEWATER CENTRE - DARTFORD',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '87:155'
      }
    ],
    '28_MALL VISION -LIVERPOOL ONE S/C - LIVERPOOL': [
      {
        'FORMAT': 'MALL VISION -LIVERPOOL ONE S/C - LIVERPOOL',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '648',
        'Width': '354',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'LIVERPOOL ONE S/C - LIVERPOOL',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '59:108'
      }
    ],
    '29_MALL VISION -ELDON SQUARE S/C - NEWCASTLE UPON TYNE, VICTORIA CENTRE S/C - NOTTINGHAM': [
      {
        'FORMAT': 'MALL VISION -ELDON SQUARE S/C - NEWCASTLE UPON TYNE, VICTORIA CENTRE S/C - NOTTINGHAM',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '672',
        'Width': '384',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': ' NEWCASTLE UPON TYNE, VICTORIA CENTRE S/C - NOTTINGHAM',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '4:7'
      }
    ],
    '30_MALL VISION -WESTGATE OXFORD S/C, MIDDLE SQUARE, ADJ ERNEST JONES, RIGHT OF SUPERDRY': [
      {
        'FORMAT': 'MALL VISION -WESTGATE OXFORD S/C, MIDDLE SQUARE, ADJ ERNEST JONES, RIGHT OF SUPERDRY',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '720',
        'Width': '400',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'WESTGATE OXFORD S/C, MIDDLE SQUARE, ADJ ERNEST JONES, RIGHT OF SUPERDRY',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '5:9'
      }
    ],
    '31_MALL VISION -TRINITY S/C - LEEDS': [
      {
        'FORMAT': 'MALL VISION -TRINITY S/C - LEEDS',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '792',
        'Width': '480',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'TRINITY S/C - LEEDS',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '20:33'
      }
    ],
    '32_MALL VISION -METRO CENTRE S/C - GATESHEAD, INTU LAKESIDE S/C THURROCK': [
      {
        'FORMAT': 'MALL VISION -METRO CENTRE S/C - GATESHEAD, INTU LAKESIDE S/C THURROCK',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '864',
        'Width': '512',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'METRO CENTRE S/C - GATESHEAD, INTU LAKESIDE S/C THURROCK',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '16:27'
      }
    ],
    '33_MALL VISION -LAKESIDE S/C - THURROCK': [
      {
        'FORMAT': 'MALL VISION -LAKESIDE S/C - THURROCK',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '880',
        'Width': '560',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'LAKESIDE S/C - THURROCK',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '7:11'
      }
    ],
    '34_MALL VISION - WESTGATE OXFORD S/C, LEIDEN SQUARE, BTWN JOHN LEWIS & BLACKWELLS': [
      {
        'FORMAT': 'MALL VISION - WESTGATE OXFORD S/C, LEIDEN SQUARE, BTWN JOHN LEWIS & BLACKWELLS',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '1000',
        'Width': '600',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'WESTGATE OXFORD S/C, LEIDEN SQUARE, BTWN JOHN LEWIS & BLACKWELLS',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '3:5'
      }
    ],
    '35_MALL VISION - ST DAVIDS S/C - CARDIFF, WHITE ROSE S/C - LEEDS': [
      {
        'FORMAT': 'MALL VISION - ST DAVIDS S/C - CARDIFF, WHITE ROSE S/C - LEEDS',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '1040',
        'Width': '600',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': 'ST DAVIDS S/C - CARDIFF, WHITE ROSE S/C - LEEDS',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '15:26'
      }
    ],
    '36_TRANSVISON - KINGS CROSS STN': [
      {
        'FORMAT': 'TRANSVISON - KINGS CROSS STN',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '352',
        'Width': '608',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '20s'
        },
        'Location': 'KINGS CROSS STN',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '19:11'
      }
    ],
    '37_TRANSVISON - FENCHURCH STREET STN, CANNON STREET STN, BLACKFRIARS STN, ST PANCRAS STN, NEWCASTLE CENTRAL STN': [
      {
        'FORMAT': 'TRANSVISON - FENCHURCH STREET STN, CANNON STREET STN, BLACKFRIARS STN, ST PANCRAS STN, NEWCASTLE CENTRAL STN',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '360',
        'Width': '720',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '20s'
        },
        'Location': ' FENCHURCH STREET STN, CANNON STREET STN, BLACKFRIARS STN, ST PANCRAS STN, NEWCASTLE CENTRAL STN',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      }
    ],
    '38_TRANSVISON - LEEDS STN, LONDON BRIDGE STN': [
      {
        'FORMAT': 'TRANSVISON - LEEDS STN, LONDON BRIDGE STN',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '400',
        'Width': '800',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '20s'
        },
        'Location': 'LEEDS STN, LONDON BRIDGE STN',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      }
    ],
    '39_TRANSVISON - WATERLOO STN, VICTORIA STN, CHARING CROSS STN, EUSTON STN, GLASGOW CENTRAL STN': [
      {
        'FORMAT': 'TRANSVISON - WATERLOO STN, VICTORIA STN, CHARING CROSS STN, EUSTON STN, GLASGOW CENTRAL STN',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '480',
        'Width': '896',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '20s'
        },
        'Location': 'WATERLOO STN, VICTORIA STN, CHARING CROSS STN, EUSTON STN, GLASGOW CENTRAL STATION',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '28:15'
      }
    ],
    '40_TRANSVISON - MANCHESTER PICCADILLY STN': [
      {
        'FORMAT': 'TRANSVISON - MANCHESTER PICCADILLY STN',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '20s'
        },
        'Height': '500',
        'Width': '1000',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '20s'
        },
        'Location': 'MANCHESTER PICCADILLY STN',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '2:1'
      }
    ]
  },
  '0_Portrait': {
    '0_D6 PORTRAIT': [
      {
        'FORMAT': 'D6 PORTRAIT ',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'many MO',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV',
          'Frame Rate': '25 FPS',
          'Copy Length': '12s'
        },
        'Location': '',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '1_D6 ROADSIDE': [
      {
        'FORMAT': 'D6 ROADSIDE',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '12s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux UK',
        'video': {},
        'Location': '',
        'Content Type': 'Image',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '2_D6 RAIL': [
      {
        'FORMAT': 'D6 RAIL',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV, MP4',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': '',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '3_D6 MALLS & SUPERMAKETS': [
      {
        'FORMAT': 'D6 MALLS & SUPERMAKETS',
        'image': {
          'File Size': '5MB',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '5MB',
          'Sound': 'No',
          'Supported Formats': 'MOV, MP4',
          'Frame Rate': '25 FPS',
          'Copy Length': '6s'
        },
        'Location': '',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '4_SMALL PORTRAIT': [
      {
        'FORMAT': 'SMALL PORTRAIT',
        'image': {
          'File Size': '2GB or less',
          'Supported Formats': 'JPEG',
          'Copy Length': '6s'
        },
        'Height': '1280',
        'Width': '720',
        'Media Owner': 'JC Decaux UK',
        'video': {
          'File Size': '2GB or less',
          'Sound': 'No',
          'Supported Formats': 'MP4, MOV',
          'Copy Length': '6s'
        },
        'Location': 'DEPS',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '5_DIGITAL\nCITY NET\n(DCN) - Berlin, Hamburg': [
      {
        'FORMAT': 'DIGITAL\nCITY NET\n(DCN) - Berlin, Hamburg',
        'image': {
          'File Size': '2GB or less',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux GER',
        'video': {
          'File Size': '2GB or less',
          'Sound': 'No',
          'Supported Formats': 'MP4 - Slow graphic animations are permitted, but actual video footage is not allowed.',
          'Copy Length': '10s'
        },
        'Location': 'Berlin, Hamburg',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '6_DIGITAL\nDELUXE NET\n(DDN) - Berlin, Bremen, Dortmund,\nDüsseldorf, Hamburg, Cologne,\nMannheim,Munich, Wiesbaden': [
      {
        'FORMAT': 'DIGITAL\nDELUXE NET\n(DDN) - Berlin, Bremen, Dortmund,\nDüsseldorf, Hamburg, Cologne,\nMannheim,Munich, Wiesbaden',
        'image': {
          'File Size': '2GB or less',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux GER',
        'video': {
          'File Size': '2GB or less',
          'Sound': 'No',
          'Supported Formats': 'MP4 - Slow graphic animations are permitted, but actual video footage is not allowed.',
          'Copy Length': '10s'
        },
        'Location': 'Berlin, Bremen, Dortmund,\nDüsseldorf, Hamburg, Cologne,\nMannheim, Munich, Wiesbaden',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '7_DIGITAL\r\nUNDERGROUND BERLIN\r\n(DUB)': [
      {
        'FORMAT': 'DIGITAL\r\nUNDERGROUND BERLIN\r\n(DUB)',
        'image': {
          'File Size': '2GB or less',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux GER',
        'video': {
          'File Size': '2GB or less',
          'Sound': 'No',
          'Supported Formats': 'MP4 - Slow graphic animations and actual video footage are permitted.',
          'Copy Length': '10s'
        },
        'Location': 'Berlin',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ],
    '8_SPAIN D6': [
      {
        'FORMAT': 'SPAIN D6',
        'image': {
          'File Size': '2GB or less',
          'Supported Formats': 'JPEG',
          'Copy Length': '10s'
        },
        'Height': '1920',
        'Width': '1080',
        'Media Owner': 'JC Decaux GER',
        'video': {
          'File Size': '20-30 Mgas. ',
          'Sound': 'No',
          'Supported Formats': 'MP4',
          'Frame Rate': '21 to 25 fps',
          'Copy Length': '10s'
        },
        'Location': 'SPAIN ',
        'Content Type': 'Image & Video',
        'Rotation': '',
        'Aspect Ratio': '9:16'
      }
    ]
  }
}
