import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlService } from '../../services/control.service';
import { CampaignService } from '../../services/campaign.service';
import { FcAuthService } from '../../services/fc-auth.service';
import { skip, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { IBudget, ICampaign } from '../../app.model';
import { IScheduleModel } from '../../models/schedule.model';
import { ShopifyService } from '../../services/shopify.service';
import dayjs from 'dayjs';

const NEXT_STEP = 3;

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();

  private _campaignConfig: IBudget = {
    budget: 0,
    dailyBudget: 0,
  };

  selectedDates;
  location;
  skipCampaignDelete = false;

  public processing = false;

  constructor(
    private controlSvc: ControlService,
    private campaignSvc: CampaignService,
    private flowCityAuth: FcAuthService,
    private shopifySvc: ShopifyService,
  ) {
  }

  ngOnInit(): void {
    this.location = this.flowCityAuth.location;
    this.updateSelectedDates();
    if (this.campaign) {
      this._campaignConfig = {
        budget: Number(this.campaign.budget),
        dailyBudget: Number(this.campaign.budget_daily),
      };
    }

    this.controlSvc.next$
      .pipe(
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.estimateCampaign());
  }

  public set campaignConfig(data: IBudget) {
    this._campaignConfig = data;
  }

  public get campaignConfig(): IBudget {
    return this._campaignConfig;
  }

  private updateSelectedDates(): void {
    this.selectedDates = {
      startDate: this.schedule ? dayjs(this.schedule.schedule_start) : this.minDate,
      endDate: this.schedule ? dayjs(this.schedule.schedule_stop) : this.minDate,
    };
  }

  updateCampaign(prop: string, value: number): void {
    this.campaignConfig = {...this.campaignConfig, [prop]: value};
    this.campaignSvc.campaign.budget = String(this.campaignConfig.budget);
    this.campaignSvc.campaign.budget_daily = String(this.campaignConfig.dailyBudget);
    this.campaignSvc.campaign.demand_budget = this.campaignConfig.budget;
    this.campaignSvc.campaign.demand_budget_daily = this.campaignConfig.dailyBudget;
  }

  get previewUrl(): string {
    return this.campaignSvc.selectedArtworkSrc;
  }

  get selectedFormat() {
    return this.controlSvc.selectedFormat;
  }

  choosedDate(event): void {
    const duration = event.endDate.diff(event.startDate, 'days') + 1;
    this.campaignSvc.schedule.schedule_start = event.startDate.format('YYYY-MM-DDT00:00');
    this.campaignSvc.schedule.schedule_stop = event.endDate.format('YYYY-MM-DDT23:59');
    this.campaignSvc.campaign.duration = duration;
  }

  get minDate(): dayjs.Dayjs {
    return dayjs().add(3, 'days');
  }

  createCampaign(): void {
    if (this.campaignSvc.campaign) {
      return;
    }
    this.processing = true;
    const payload = {
      name: `[SHOPIFY] Campaign ${dayjs().format('YYYY-MM-DD H:m')}`,
      duration: '1',
      budget: '0',
      impression_model: 'rtb',
      offer_postcode: this.location.postcode,
      offer_radius: 3,
      offer_type: 'multi',
      offer_origin: 'shopify'
    };

    this.campaignSvc.createCampaign(payload)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(campaign => combineLatest([
          this.campaignSvc.getSchedules(),
          this.campaignSvc.createOfferTarget()
        ]))
      )
      .subscribe(data => {
        this.updateSelectedDates();
        this.processing = false;
        console.log(data);
      },
        () => this.processing = false,);
  }

  estimateCampaign(): void {
    if (this._campaignConfig.budget === 0) {
      this.shopifySvc.showToast('Please provide budget for the campaign');
      return;
    }
    if (this._campaignConfig.dailyBudget === 0) {
      this.shopifySvc.showToast('Please provide daily budget for the campaign');
      return;
    }
    this.processing = true;
    this.skipCampaignDelete = true;
    this.campaignSvc.saveSchedule()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.campaignSvc.getEstimates()),
      )
      .subscribe(data => {
        this.processing = false;
        if (data && data.audience_reach_results && data.audience_reach_results.length) {
          const estimation = data.audience_reach_results.find(a => a.selected).formats.find(f => f.selected);
          if (estimation) {
            this.controlSvc.currentStep = NEXT_STEP;
          } else {
            this.shopifySvc.showToast(
              'We couldn\'t find any estimated screens. Please increase the budget.',
              true,
              10000
            );
          }
        } else {
          this.shopifySvc.showToast(
          'We couldn\'t find any estimated screens. Please increase the budget.',
          true,
          10000
        );
        }
        console.log(data);
      },
        () => {
          this.processing = false;
          this.shopifySvc.showToast(
              'There is something wrong with campaign settings. Please double check scheduled dates',
              true,
              10000
            );
        });
  }

  onLocationChange(data): void {
    this.campaign.latitude = data.latitude;
    this.campaign.longitude = data.longitude;
    this.campaign.offer_postcode = data.postalCode;
    this.campaign.offer_radius = data.radius;
    this.campaign.location_map = data.locationMap;
  }

  get campaign(): ICampaign {
    return this.campaignSvc.campaign;
  }

  get schedule(): IScheduleModel {
    return this.campaignSvc.schedule;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
