import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { ICampaignFile } from '../../../app.model';


export const DEFAULT_FILE_FEEDBACK = {
  fileUploading: false,
  filePercent: 0
};

@Component({
  selector: 'app-artwork-preview',
  templateUrl: './artwork-preview.component.html',
  styleUrls: ['./artwork-preview.component.scss']
})
export class ArtworkPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('artworkContainer') artworkContainer: ElementRef;

  @Input() previewSrc: SafeUrl = undefined;
  @Input() fileType = null;
  @Input() chosenFormat: any;
  @Input() fileFeedback = DEFAULT_FILE_FEEDBACK;
  @Input() file = null;
  @Input() enableCarousel = false;
  @Input() useThumbnail = false;
  @Input('uploadButton') uploadButton = true;


  @Output() emitUploadRequest = new EventEmitter();

  showControls = false;
  artworkPreviewStyle: object;
  campaignFiles: ICampaignFile[];
  current = 0;

  private _destroy$ = new Subject<any>();

  readonly maxWidth: number = 300;
  readonly maxHeight: number = 175;

  constructor(
  ) {}

  get isImage(): boolean {
    return this.fileType.includes('image') || this.useThumbnail;
  }
  get isVideo(): boolean {
    return this.fileType.includes('video');
  }

  leaveVideo(): void {
    this.videoPlayer.nativeElement.removeAttribute('controls');
  }
  enterVideo(): void {
    this.videoPlayer.nativeElement.setAttribute('controls', 'controls');
  }

  ngOnInit() {
    setTimeout(() => { this.onResize(); }, 100);
  }

  imageStyles(file: ICampaignFile = null, previewSrc: SafeUrl): any {
    if (previewSrc) {
      return {'background-image': `url('${previewSrc}')`};
    } else {
      return {'background-image': `url('${(this.useThumbnail ? file.thumbnail : this.getArtworkSrc(file)) || this.previewSrc}')`};
    }
  }

  selectFile(): void {
    this.emitUploadRequest.emit();
  }

  getArtworkSrc(file: ICampaignFile): string {
    return '';
  }

  getAspectRatio(): number {
    if (!this.chosenFormat) {
      return 1.78; // 1920x1080
    }
    const width = this.chosenFormat.artwork_spec.resolution.width;
    const height = this.chosenFormat.artwork_spec.resolution.height;

    return width / height;
  }

  @HostListener('window:resize')
  onResize(): void {

    const aspectRatio = this.getAspectRatio();
    const containerHeight = this.artworkContainer.nativeElement.offsetHeight;

    if (aspectRatio >= 1 && Math.round((this.artworkContainer.nativeElement.offsetWidth) / aspectRatio) <= containerHeight) {
      this.artworkPreviewStyle = {
        width: `${this.artworkContainer.nativeElement.offsetWidth - 30}px`,
        height: `${Math.round((this.artworkContainer.nativeElement.offsetWidth - 30) / this.getAspectRatio())}px`
      };
    } else {

      const availWidth = this.artworkContainer.nativeElement.offsetWidth - 30,
          targetWidth = Math.round(containerHeight * this.getAspectRatio());

      if (targetWidth > availWidth) {
        this.artworkPreviewStyle = {
          width:  `${availWidth}px`,
          height: `${Math.round((this.artworkContainer.nativeElement.offsetWidth - 30) / this.getAspectRatio())}px`
        }
      } else {
        this.artworkPreviewStyle = {
          width:  `${Math.round(containerHeight * this.getAspectRatio())}px`,
          height: `${containerHeight}px`
        };
      }

    }

    if (containerHeight > parseInt(this.artworkPreviewStyle['height'], 10)) {
      this.artworkPreviewStyle['marginTop'] = (containerHeight - parseInt(this.artworkPreviewStyle['height'], 10)) / 2 + 'px';
    }

    this.artworkPreviewStyle['display'] = 'block';
  }

  uploadProgressStyle(percent): any {
    let bg;
    if (percent < 50) {
      const deg = 90 + (3.6 * percent);
      bg = `linear-gradient(90deg, #E0E0E0 50%, transparent 50%, transparent), linear-gradient(${deg}deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    } else {
      const deg = -90 + (3.6 * (percent - 50));
      bg = `linear-gradient(${deg}deg, #03F7FF 50%, transparent 50%, transparent), linear-gradient(270deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    }
    return {background: bg};
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }


}
