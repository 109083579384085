import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IArtwork } from '../app.model';
import { FORMATS } from '@const/artwork-generator.const';
import { STEPS } from '@const/steps.consts';

@Injectable({
  providedIn: 'root'
})
export class ControlService {

  private _currentStep$ = new BehaviorSubject<number>(0);
  public currentStep$ = this._currentStep$.asObservable();
  public customNextStepLabel: string = null;

  product: any;
  steps: any;

  public next$ = new BehaviorSubject<any>(null);
  public nextDisabled$ = new BehaviorSubject<boolean>(false);
  public prevDisabled$ = new BehaviorSubject<boolean>(false);

  formats = FORMATS;
  selectedFormat = null;

  constructor() {
    this.selectedFormat = this.formats.portrait;
    this.steps = STEPS;
  }

  set currentStep(step: number) {
    this._currentStep$.next(step);
  }

  get currentStep(): number {
    return this._currentStep$.getValue();
  }

  get step(): any {
    const _step = this.steps.find(step => step.id === this._currentStep$.getValue());
    return this.customNextStepLabel ? {..._step, nextLabel: this.customNextStepLabel} : _step;
  }

  set nextDisabled(state: boolean) {
    this.nextDisabled$.next(state);
  }

  set prevDisabled(state: boolean) {
    this.prevDisabled$.next(state);
  }

}
