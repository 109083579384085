import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IArtwork, ICampaign, ICampaignFile } from '../../app.model';
import { ArtworkGeneratorService } from '../../services/artwork-generator.service';
import { ControlService } from '../../services/control.service';
import { distinctUntilChanged, filter, mergeMap, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs';
import { FcAuthService } from '../../services/fc-auth.service';
import { CampaignService } from '../../services/campaign.service';
import { FileService } from '../../services/file.service';
import { CreativeSpecsService } from '@module/creative-specs/creative-specs.service';
import { ShopifyService } from '../../services/shopify.service';
import dayjs from 'dayjs/esm';
import { SPECS } from '@const/artwork-generator.const';

const NEXT_STEP = 2;

export const DEFAULT_FILE_FEEDBACK = {
  fileUploading: false,
  filePercent: 0
};

@Component({
  selector: 'app-artwork-generator',
  templateUrl: './artwork-generator.component.html',
  styleUrls: ['./artwork-generator.component.scss'],
  providers: [
    ArtworkGeneratorService
  ]
})
export class ArtworkGeneratorComponent implements OnInit, OnDestroy {

  @ViewChild('fileToUpload') fileToUpload: ElementRef;

  skipCampaignDelete = false;

  private destroy$ = new Subject();
  public processing = false;
  public cover = null;
  config: any;

  public data = {};
  public desc: string;
  public hashtags: string;
  public customData = {};
  public layouts;
  public layout;
  location;

  format = '0_D6 PORTRAIT';

  specs: any = SPECS;

  filesToUpload = [];
  fileFeedback = DEFAULT_FILE_FEEDBACK;

  notification$ = new BehaviorSubject<any>(null);

  constructor(
    private service: ArtworkGeneratorService,
    private controlSvc: ControlService,
    private flowCityAuth: FcAuthService,
    private campaignSvc: CampaignService,
    private fileService: FileService,
    private specsSvc: CreativeSpecsService,
    private shopifySvc: ShopifyService,
  ) {
    this.notification$
      .pipe(
        filter(d => d !== null),
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(data => {
        this.shopifySvc.showToast(data.message, data.isError);
      });
  }

  set notification(data) {
    this.notification$.next(data);
  }

  ngOnInit(): void {
    this.location = this.flowCityAuth.location;
    if (!this.campaign) {
      this.createCampaign(this.controlSvc.product)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.init();
        });
    } else {
      this.init();
    }

    // this.specsSvc.getSpecs()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(
    //     data => {
    //       this.specs = data;
    //     });

    this.controlSvc.next$
      .pipe(
        skip(1),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.campaignSvc.campaign = {...this.campaignSvc.campaign, media_file_thumbnail: this.selectedArtwork.thumbnail };
        this.campaignSvc.saveCampaign(this.campaign).subscribe(() => {
          this.skipCampaignDelete = true;
          this.controlSvc.currentStep = NEXT_STEP;
        });
      });

    this.fileService.artworksNotReady().pipe(takeUntil(this.destroy$))
      .subscribe(artworksNotReady => {
        if (this.file.thumbnail_inprogress || this.file.transcode_inprogress) {
          this.notification = {message: 'Processing file...'};
        }
        if (this.file._validResolution === false) {
          this.notification = {message: 'Artwork has invalid size. Please upload 1080x1920px image.', isError: true};
        }
        this.controlSvc.nextDisabled = (this.campaignSvc.selectedArtwork === null || this.campaignSvc.selectedArtwork === undefined) || artworksNotReady;
        this.controlSvc.prevDisabled = (this.campaignSvc.selectedArtwork === null && this.campaignSvc.selectedArtwork === undefined) || (artworksNotReady && this.file._validResolution);
        if (!artworksNotReady) {
          this.selectedArtwork = this.fileService.campaignFiles[0];
          this.campaignSvc.campaign = {...this.campaignSvc.campaign, media_file_thumbnail: this.selectedArtwork.thumbnail };
        }
      });

  }

  private init(): void {
    this.fileService.init(this.campaignSvc.campaign, false).subscribe();
    this.fileService.format = this.controlSvc.selectedFormat;
    this.flowCityAuth.refreshSessionData().subscribe();
    // this.data['image'] = this.controlSvc.product.image.url;
    // this.config = this.flowCityAuth.shopifyConfig.artworkGenerator;
    // this.service.getLayoutConfig(this.config.template)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(data => {
    //     this.layouts = data;
    //     this.layout = this.layouts[this.config.default || 'portrait'];
    //     console.log(this.layout);
    //     this.layout.texts.map(text => {
    //       this.data[text.field] = { value: this.controlSvc.product.title };
    //     });
    //     if (!this.campaignSvc.artwork) {
    //       this.refreshArtwork();
    //     }
    //   });
  }

  get campaign(): ICampaign {
    return this.campaignSvc.campaign;
  }

  refreshArtwork(): void {
    this.processing = true;
    this.service.generateArtwork(this.config.template, this.data).subscribe(
      res => {
        this.campaignSvc.artwork = {...res.data[0], id: null};
        this.processing = false;
      },
      () => this.processing = false,
    );
  }

  // get previewUrl(): string {
  //   return this.selectedArtwork;
  //   //return this.campaignSvc.artwork && this.campaignSvc.artwork.artwork_url;
  // }

  get selectedFormat() {
    return this.controlSvc.selectedFormat;
  }

  addFile(event) {
    this.filesToUpload = [];
    let type = 'multi';
    this.processing = true;

    const file = this.fileToUpload.nativeElement.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      this.processing = false;
      this.notification = {message: 'Unsupported file type. Please upload JPEG or PNG image file.', isError: true};
      return;
    }
    this.filesToUpload.push(file);

    event.target.value = '';

    from(this.filesToUpload)
      .pipe(
        mergeMap(file => this.fileService.initFileUpload(file), null, 4)
      )
      .subscribe(
        file => {
          if (!this.fileService.isMulti) {
            if (file['uploadedFile']) {
              this.fileFeedback = DEFAULT_FILE_FEEDBACK;
              this.selectedArtwork = this.fileService.campaignFiles[0];
              this.controlSvc.nextDisabled = this.campaignSvc.selectedArtwork === null || this.campaignSvc.selectedArtwork === undefined;
            } else {
              this.fileFeedback = {
                fileUploading: true,
                filePercent: file['progress']
              };
            }
          }
          this.processing = false;
        },
        () => this.processing = false,
      );

    // if (this.offerService.isRegular) {
    //   const fileType = this.filesToUpload[0].type;
    //   type = fileType.includes('video') ? 'video' : 'image';
    //   this.offerService.currentOffer.offer_type = type;
    //   this.offerService.save().subscribe();
    // }
  }

  createCampaign(product): Observable<any> {
    if (this.campaignSvc.campaign) {
      return;
    }
    this.processing = true;
    const productSKU = product.variants && product.variants.edges && product.variants.edges.length && product.variants.edges[0].node.sku;
    const payload = {
      name: `[SHOPIFY] Campaign for ${product.title} ${dayjs().format('YYYY-MM-DD HH:mm')}`,
      duration: '1',
      budget: '0',
      demand_budget: 0,
      impression_model: 'rtb',
      offer_postcode: this.location.postcode,
      offer_radius: 3,
      offer_type: 'multi',
      text1: JSON.stringify({product_id: product.id, title: product.title, sku: productSKU}),
      offer_origin: 'shopify'
    };

    return this.campaignSvc.createCampaign(payload)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          combineLatest([
            this.campaignSvc.getSchedules(),
            this.campaignSvc.createOfferTarget()
          ]).subscribe();
        }),
        tap(() => this.processing = false),
      );
  }

  selectFile(): void {
    this.fileToUpload.nativeElement.click();
  }

  get fileSrc(): Observable<string> {
    return new Observable(subscriber => {
      if (this.selectedArtwork) {
        this.fileService.getArtworkSrcAsync(this.selectedArtwork)
           .subscribe(
           redirectUrl => {
             subscriber.next(redirectUrl);
             subscriber.complete();
           });
       }
    });
  }

  get selectedArtwork(): ICampaignFile {
    return this.campaignSvc.selectedArtwork;
  }

  set selectedArtwork(artwork: ICampaignFile) {
    this.campaignSvc.selectedArtwork = artwork;
    this.fileSrc
      .pipe(takeUntil(this.destroy$))
      .subscribe(url => this.campaignSvc.selectedArtworkSrc = url);
  }

  get selectedArtworkSrc(): string {
    return this.campaignSvc.selectedArtworkSrc;
  }

  contentType(subFormat: string): any {
    return this.formatSpecs[subFormat][0]['Content Type'];
  }

  get formatSpecs(): any {
    return this.specs['0_Portrait'];
  }

  get file(): ICampaignFile {
    return this.fileService.campaignFiles.length > 0 && this.fileService.campaignFiles[0];
  }

  ngOnDestroy(): void {
    if (!this.skipCampaignDelete) {
      this.campaignSvc.destroyCampaign();
    } else if (this.file && this.file._validResolution === false) {
      this.fileService.deleteFile(this.file);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

}
