<div class="budget-thumb-railway" [class.editable]="editable">
  <div class="budget-thumb-label"
    [style.left.%]="sliderPos">
    <ng-container *ngIf="editable; else valueOnly">
      <div class="input-control right"
           *ngIf="editable"
           (click)="toggleEditable(true)">
        <i class="fa fa-check"></i>
      </div>

      <input currencyMask
             *ngIf="editable"
             [(ngModel)]="manualValue"
             maxlength="7"
             max="1000000"
             [options]="currencyOptions">

      <div class="input-control left"
           *ngIf="editable"
           (click)="toggleEditable(false)">
        <i class="fa fa-times"></i>
      </div>

    </ng-container>

    <ng-template #valueOnly>
      <h3>{{ options.prefix }} {{sliderUsed ? scaleTransform(rangeValue) : displayedValue}}</h3>
    </ng-template>

    <p *ngIf="thumbPointerSubline">{{ thumbPointerSubline }}</p>
    <div class="budget-thumb-pointer"></div>
  </div>
</div>

<p-slider #slider
        (onSlideEnd)="setValue($event)"
        (onChange)="onChange($event)"
        [(ngModel)]="rangeValue"
        [max]="discretePoints"
        [min]="min"
        [disabled]="disabled || editable">
</p-slider>
