import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DistanceWidget, RadiusWidget } from '@module/campaign-location/distance-widget.class';
import { CampaignLocationService } from '@module/campaign-location/services/campaign-location.service';
import { ICampaignLocation } from '@module/campaign-location/campaign-location.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-location',
  templateUrl: './campaign-location.component.html',
  styleUrls: ['./campaign-location.component.scss']
})
export class CampaignLocationComponent implements OnInit, OnDestroy {

  @ViewChild('input', { static: false }) input: ElementRef;

  private destroy$ = new Subject();

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() postalCode: string;
  @Input() locationMap: string;
  @Input() radius;
  @Input() btnClass = 'btn';
  @Input() btnClassConfirm = 'btn';
  @Output() updated = new EventEmitter<ICampaignLocation>();

  editor = false;
  dataChanged = false;

  map: google.maps.Map;
  autocomplete: google.maps.places.Autocomplete;
  data: ICampaignLocation;
  distanceWidget: DistanceWidget;

  constructor(
    private service: CampaignLocationService,
  ) { }

  ngOnInit(): void {
    this.updateData();
    if (!this.data.locationMap) {
      this.service.getImage(this.data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(img => {
          this.data.locationMap = img;
          this.updated.emit(this.data);
        });
    }
  }

  initMap(): void {
    this.updateData();
    this.map = new google.maps.Map(document.getElementById('map'), {
      mapId: '9b07a10e479aab67',
      zoom: 13,
      disableDefaultUI: true,
    });

    this.map.setCenter({
      lat: this.data.latitude,
      lng: this.data.longitude
    });

    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      language: 'en',
      types: ['geocode'],
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, options);
    this.autocomplete.bindTo('bounds', this.map);

    this.autocomplete.addListener('place_changed', () => {
      this.distanceWidget.setMap(null);
      const place = this.autocomplete.getPlace();
      this.data.postalCode = place.formatted_address;
      this.map.setCenter(place.geometry.location);
      this.distanceWidget.setMap(this.map);
      this.map.fitBounds(this.distanceWidget.getBounds());
    });

    this.distanceWidget = new DistanceWidget(this.data.radius);
    google.maps.event.addListener(this.distanceWidget, 'distance_changed', () => {
      this.displayInfo(this.distanceWidget);
    });
    google.maps.event.addListener(this.distanceWidget, 'position_changed', () => {
      this.displayInfo(this.distanceWidget);
    });

    setTimeout(() => {
      this.distanceWidget.setMap(this.map);
      this.map.fitBounds(this.distanceWidget.getBounds());
    });
  }

  updateData(): void {
    this.data = {
      latitude: this.latitude || 51.5049489,
      longitude: this.longitude || -0.0195006,
      radius: this.radius,
      locationMap: this.locationMap || this.data && this.data.locationMap ? this.data.locationMap : '',
      postalCode: this.postalCode,
    };
  }

  displayInfo(widget): void {
    this.data = {
      ...this.data,
      latitude: widget.get('position').lat(),
      longitude: widget.get('position').lng(),
      radius: Number(RadiusWidget.getKMToMi(widget.get('distance')).toFixed(1)),
    };
  }

  public toggleMode(): void {
    if (!this.editor) {
      this.editor = true;
      setTimeout(() => this.initMap());
    }
  }

  public close(save = false, event): void {
    event.stopImmediatePropagation();
    if (save) {
      this.service.getImage(this.data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(img => {
          this.data.locationMap = img;
          this.updated.emit(this.data);
          this.clearMap();
          this.editor = false;
        });
    } else {
      this.clearMap();
      this.editor = false;
    }
  }

  onRadiusChange(radius: number): void {
    this.radius = radius;
    this.updated.emit(this.data);
  }

  private clearMap(): void {
    this.distanceWidget.setMap(null);
    this.distanceWidget.clear();
    delete this.distanceWidget;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
