import { Component, Input, OnInit } from '@angular/core';
import { AvailabilityHelperService } from './availability-helper.service';
import { IAvailabilityGraphData } from './availability.model';
import { ICampaign } from '../../../app.model';


@Component({
  selector: 'app-availability-graph',
  templateUrl: './availability-graph.component.html',
  styleUrls: ['./availability-graph.component.scss'],
  providers: [AvailabilityHelperService]
})
export class AvailabilityGraphComponent implements OnInit {

  @Input() campaign: ICampaign;

  output: IAvailabilityGraphData;
  days = null;
  showGraph = false;
  noData = false;

  constructor(
    private service: AvailabilityHelperService,
  ) { }

  ngOnInit(): void {
    setTimeout(() =>
    this.service.loadData(this.campaign)
      .subscribe(d => {
        this.output = this.service.parse();
        this.days = Object.keys(this.output);
        this.showGraph = true;
      },
        err => this.noData = true,
      ), 1000
    );

  }

  getStyle(item): any {
    const max = Math.max(...this.days.map(el => this.output[el].impressions));
    return {
      height: `${ (item.impressions * 100) / max }%`,
    };
  }

}
