import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ControlService } from '../../services/control.service';
import { FcAuthService } from '../../services/fc-auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(
    private controlSvc: ControlService,
    private flowCityAuth: FcAuthService,
  ) { }

  ngOnInit(): void {
  }

  get currentStep(): Observable<number> {
    return this.controlSvc.currentStep$;
  }

  get step(): any {
    return this.controlSvc.step;
  }

  get isAuthenticated(): Observable<boolean> {
    return this.flowCityAuth.isAuthenticated;
  }

  get sessionReady(): Observable<boolean> {
    return this.flowCityAuth.sessionReady;
  }

}
