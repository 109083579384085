import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import random from 'lodash.random';
import { ICampaign, ICampaignFile, ICampaignFileReadiness, IFormat } from '../app.model';
import { environment } from '@env/environment';
import dayjs from 'dayjs';

const DEFAULT_FILE_FEEDBACK = {
  fileUploading: false,
  filePercent: 0
};

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private _campaignFiles$ = new BehaviorSubject([]);
  public campaignFiles$: Observable<ICampaignFile[]> = this._campaignFiles$.asObservable();
  private currentOffer: ICampaign = null;
  public isMulti = false;
  public fileFeedback = DEFAULT_FILE_FEEDBACK;
  public uploadedFile = new BehaviorSubject(null);
  private readinessCheckUnderway = false;
  private _format: IFormat;
  private _loading$ = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading$.asObservable();
  private _sortChanged = false;

  constructor(
    private http: HttpClient,
  ) {
    // this.offerService.formatChanged
    //   .subscribe(
    //   () => this.validateArtworks()
    //   );

  }

  init(offer: ICampaign, multi = false): Observable<any> {
    this.currentOffer = offer;
    this.isMulti = multi;
    return this.fetch();
  }

  private set loading(state: boolean) {
    this._loading$.next(state);
  }

  public set format(format: IFormat) {
    this._format = format;
  }

  public get campaignFiles(): ICampaignFile[] {
    return this._campaignFiles$.getValue();
  }

  public set campaignFiles(files: ICampaignFile[]) {
    this._campaignFiles$.next(files);
  }

  public getCampaignFilesByResolution(width: number = null, height: number = null): ICampaignFile[] {
    return this.campaignFiles.filter(f => f.width === width && f.height === height);
  }

  private isValidResolution(format, width, height): boolean {
    if (width && height &&
        format
        && format.artwork_spec
        && format.artwork_spec.resolution
        && format.artwork_spec.resolution.width > 0
        && format.artwork_spec.resolution.height > 0) {
      return width === format.artwork_spec.resolution.width && height === format.artwork_spec.resolution.height;
    } else {
      return true;
    }
  }

  private validateArtworks() {
    let notifyUser = false;
    const format = this._format;
    // const notify = () => {
    //   this.feedbackService.message = 'Some of the artworks have incorrect dimensions. Please double check the artwork resolution.';
    // };

    if (format) {
      this.campaignFiles = this.campaignFiles.map(f => {
        const file = {...f};
        const isValidResolution = this.isValidResolution(format, f.width, f.height);
        if (!isValidResolution && file._validResolution !== false) {
          notifyUser = true;
        }
        file._validResolution = isValidResolution;
        return file;
      });
      // if (notifyUser) {
      //   notify();
      // }
      console.log(this.campaignFiles);
    }
  }

  fetch(checkThumbnail = false): Observable<any> {
    return new Observable(subscriber => {
      if (this.currentOffer) {
        this.loading = true;
        const waitForThumbnails = () => {
          this.checkArtworksReadiness();
        };

        if (this.currentOffer.offer_type === 'multi' &&
          this.currentOffer.multi_offer_artworks &&
          this.currentOffer.multi_offer_artworks.length > 0
        ) {
          this.campaignFiles = this.currentOffer.multi_offer_artworks.map(
            f => ({
              ...f,
              _uid: Math.random().toString(36).substring(7),
              _validResolution: true,
              redirect_link: this.getUniqueUri(f.download_link, '-link'),
              download_link: this.getUniqueUri(f.download_link),
            })
          ).sort((a, b) => dayjs(a.created) < dayjs(b.created) ? -1 : 1);

          if (checkThumbnail) {
            waitForThumbnails();
          }
          this.loading = false;
          subscriber.next();
          subscriber.complete();
        } else {
          const filesUri = this.currentOffer.uris ? this.currentOffer.uris.Files : `${this.currentOffer.uri}/files`;
          this.http.get<ICampaignFile[]>(`${environment.fcApiUrl}${filesUri}`)
            .subscribe(files => {
              this.campaignFiles = files.map(
                f => ({
                  ...f,
                  _uid: Math.random().toString(36).substring(7),
                  _validResolution: true,
                  redirect_link: this.getUniqueUri(f.download_link, '-link'),
                  download_link: this.getUniqueUri(f.download_link),
                })
              );
              this.validateArtworks();
              if (checkThumbnail) {
                this.checkArtworksReadiness();
              }
              this.loading = false;
              subscriber.next();
              subscriber.complete();
            });
        }
      } else {
        subscriber.error();
        subscriber.complete();
      }
    });
  }

  append(files: ICampaignFile[]): void {
    this.campaignFiles = [...this.campaignFiles, ...files];
    this.validateArtworks();
    this.campaignFiles.forEach((file, idx) => {
      if (file.thumbnail_inprogress) {
        this.checkArtworksReadiness();
      }
    });
  }

  private checkArtworks() {
    this.campaignFiles.forEach((file, idx) => {
      if (file.thumbnail_inprogress) {
        this.checkArtworksReadiness();
      }
    });
  }

  initFileUpload(file): Observable<any> {
    let uri = this.currentOffer.uris ? this.currentOffer.uris.Files  : `${this.currentOffer.uri}/files`;
    let method = 'POST';
    const uid = Math.random().toString(36).substring(7);
    if (!this.isMulti) {
      if (this.campaignFiles.length > 0) {
        uri = this.campaignFiles[0].uris.Upload;
        method = 'PUT';
      }
    }

    if (!this.isMulti) {
      this.campaignFiles = [];
    }
    const files = this.campaignFiles;
    files.push({_placeholder: true, _uid: uid} as ICampaignFile);
    this.campaignFiles = files;

    this.fileFeedback.fileUploading = true;
    return new Observable(subscriber => {
      this.http.request<any>(method, `${environment.fcApiUrl}${uri}`, {body: {name: file.name, mime_type: file.type}})
        .subscribe(
          data => {
            this.uploadFile(data, file)
              .subscribe(
                fileData => {
                  if (fileData.type === HttpEventType.UploadProgress) {
                    const _files = this.campaignFiles;
                    const idx = _files.findIndex(f => f._uid === uid);
                    if (idx > -1) {
                      _files[idx] = {..._files[idx], _progress: Math.round(fileData.loaded / fileData.total * 100)};
                      this.campaignFiles = _files;
                    }
                    subscriber.next({
                      progress: Math.round(fileData.loaded / fileData.total * 100),
                      file: file,
                      uploadedFile: null
                    });
                  } else if (fileData.type === HttpEventType.Response) {
                    this.finalizeFileUpload(data, fileData, file, method, uid)
                      .subscribe(
                        (uploadedFile) => {
                          subscriber.next({
                            progress: 0,
                            file: file,
                            uploadedFile: uploadedFile
                          });
                          subscriber.complete();
                          this.fileFeedback.fileUploading = false;
                          this.campaignFiles = [...this.campaignFiles];
                        },
                        (err) => subscriber.error(err)
                      );
                  }

                },
                err => subscriber.error(err)
              );
          },
          err => subscriber.error(err)
        );
    });
  }

  deleteFile(file: ICampaignFile) {
    const uri = file.uri;
    file.thumbnail_inprogress = true;
    this.http.delete(`${environment.fcApiUrl}${uri}`).subscribe(
      () => {
        const files = this.campaignFiles.filter(f => f.uri !== uri);
        this.campaignFiles = files;
      }
    );
  }

  uploadFile(data: any, file): Observable<any> {
    const fileType = file.type,
          uri = data.uris.Upload,
          headers = new HttpHeaders({
          'content-type': fileType,
          'X-Upload-Content-Type': fileType
        });
    return this.http.request(new HttpRequest('POST', uri, file, { headers, reportProgress: true }));
  }

  finalizeFileUpload(data, fileData, file, method, uid): Observable<any> {
    const uri = `${environment.fcApiUrl}${data.uris.UploadedFiles || data.uris.Uploaded}`;
    const responseContent = fileData.body;
    responseContent['contentType'] = file.type;

    return this.http.request<ICampaignFile>(method, uri, {
      body: {
      'response_content': responseContent,
      'status_code': fileData.status
      }
    }).pipe(
      tap(d => {
        const files = this.campaignFiles;
        const idx = files.findIndex(f => f._uid === uid);
        if (idx > -1) {
          files[idx] = {
            ...files[idx],
            ...d,
            _placeholder: false,
            _progress: null,
            redirect_link: this.getUniqueUri(d.download_link, '-link'),
            download_link: this.getUniqueUri(d.download_link),
          };
          this.campaignFiles = files;
          this.validateArtworks();
          this.checkArtworksReadiness();
        }
      }),
      tap(d => this.uploadedFile.next(d))
    );
  }

  getFile(offerFile: ICampaignFile): Observable<ICampaignFile> {
    const uri = `${environment.fcApiUrl}${offerFile.uri}`;
    return this.http.get<ICampaignFile>(uri);
  }

  getArtworkReadinessStatus(): Observable<ICampaignFileReadiness[]> {
    return this.http.get<ICampaignFileReadiness[]>(`${environment.fcApiUrl}${this.currentOffer.uris.GetArtworkReadiness}`);
  }

  replaceFile(offerFile: ICampaignFile): void {
    const files = this.campaignFiles;
    const idx = this.campaignFiles.findIndex(file => file.uri === offerFile.uri);
    if (idx > -1) {
      files[idx] = offerFile;
      this.campaignFiles = [...files];
    }
  }

  getImageDimensions(file): Observable<any> {
      return new Observable(subscriber => {
          const reader = new FileReader();
          reader.onload = (event) => {
              const img = new Image();
              img.src = event.target['result'] as string;
              img.onload = (e) => {
                  subscriber.next({width: e.target['width'], height: e.target['height']});
                  subscriber.complete();
              };
          };
          reader.readAsDataURL(file);
      });
  }

  checkArtworkDimensions(format: IFormat, file) {
      return new Observable(subscriber => {
        if (format
            && format.artwork_spec
            && format.artwork_spec.resolution
            && format.artwork_spec.resolution.width > 0
            && format.artwork_spec.resolution.height > 0) {
            this.getImageDimensions(file).subscribe(
              dims => {
                    if (dims.width !== format.artwork_spec.resolution.width
                        || dims.height !== format.artwork_spec.resolution.height) {
                        subscriber.error(false);
                    } else {
                        subscriber.next(true);
                        subscriber.complete();
                    }
                }
            );
        }
      });
  }

  public getAllThumbnails(): string {
    return (this.campaignFiles && this.campaignFiles.length > 0) ? this.campaignFiles.map(file => file.thumbnail).join('|') : null;
  }

  private isArtworkReady(uri): boolean {
    const artwork = this.campaignFiles.find(a => a.uri === uri);
    return artwork && !(artwork.thumbnail_inprogress || artwork.transcode_inprogress);
  }

  private checkArtworksReadiness(): void {
    const wait = 5000;
    let retry = 20;

    const checkReadiness = () => {
      console.log('Checking artworks - retry ', retry);
      this.campaignFiles = [...this.campaignFiles];
      this.readinessCheckUnderway = true;
      --retry;
      this.getArtworkReadinessStatus()
        .subscribe(data => {
          data.filter(a => a.ready).forEach(a => {
            const files = this.campaignFiles;
            if (!this.isArtworkReady(a.uri)) {
              const fileIdx = files.findIndex(f => f.uri === a.uri);
              this.getFile(files[fileIdx]).subscribe(
                fileData => {
                  const t = {
                    ...files[fileIdx],
                    ...fileData,
                    redirect_link: this.getUniqueUri(files[fileIdx].download_link, '-link'),
                    download_link: this.getUniqueUri(files[fileIdx].download_link),
                  };
                  console.log(t);
                  files[fileIdx] = t;
                  this.campaignFiles = [...files];
                  this.validateArtworks();
                }
              );
            }
          });
          if (data.filter(a => !a.ready).length > 0 && retry > 0) {
            console.log('Not all artworks ready');
            setTimeout(() => checkReadiness(), wait);
          } else {
            this.readinessCheckUnderway = false;
          }
        });
    };
    this.campaignFiles = [...this.campaignFiles];
    !this.readinessCheckUnderway && checkReadiness();
  }

  private getUniqueUri(uri, postfix = ''): string {
    return `${uri.split('?')[0]}${ postfix }?_t=${new Date().getTime()}`;
  }

  artworksNotReady(skipEmptyList = false): Observable<any> {
    return this.campaignFiles$
      .pipe(switchMap(files =>
        new Observable(subscriber => {
          if (files.length === 0) {
            subscriber.next(!skipEmptyList);
            subscriber.complete();
          } else {
            if ((files.filter(f => f.uri).length !== files.length) ||
              files.filter(f => f.thumbnail_inprogress || f.transcode_inprogress).length > 0 ||
              files.filter(f => !f._validResolution).length > 0) {
              subscriber.next(true);
              subscriber.complete();
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          }
        })
      ));
  }

  get checkQRCodes(): Observable<boolean> {
    return this.campaignFiles$
      .pipe(switchMap(files => of(files.filter(file => file.qrcode_detected && !file.qrcode_rendered).length === 0)));
  }

  getArtworkSrc(file: ICampaignFile): string {
    if (file && file.uris) {
      const url = new URL(file.download_link.includes('http') ? file.download_link : `${environment.fcApiUrl}${file.download_link}`);
      const queryString = new URLSearchParams(url.search);
      // queryString.append('os_user', this.cookieService.get('X-FlowCity-Session-User'));
      url.search = queryString.toString();
      return url.toString();
    } else {
      return '';
    }
  }

  getArtworkSrcAsync(file: ICampaignFile): Observable<string> {
    if (file.redirect_link.startsWith('http')) {
      return of(file.redirect_link);
    }
    const url = `${ environment.fcApiUrl }${ file.redirect_link }`;
    return this.http.get(url)
      .pipe(
        filter(res => res !== null),
        map((res: any) => res.redirect_url)
      );
  }

  getMimeType(file: ICampaignFile) {
    return file.mime_type.split('/')[0];
  }

  reset(): void {
    this.uploadedFile.next(null);
    this.campaignFiles = [];
    this.currentOffer = null;
    this.fileFeedback = DEFAULT_FILE_FEEDBACK;
  }

  generateQRCode(file: ICampaignFile, content): Observable<ICampaignFile> {
    const url = `${environment.fcApiUrl}${file.uris.GenerateQrcode}`;
    const data = {content};
    return this.http.post<ICampaignFile>(url, data)
      .pipe(
        map(f => this.updateFields(f, true)),
        tap(f => {
          this.replaceFile(f);
          this.checkArtworks();
        }),
      );
  }

  submitForReview(file: ICampaignFile): Observable<ICampaignFile> {
    const url = `${environment.fcApiUrl}${file.uris.SubmitForReview}`;
    return this.http.post<ICampaignFile>(url, {})
      .pipe(
        map(f => this.updateFields(f)),
        tap(f => this.replaceFile(f)),
      );
  }

  checkReviewState(file: ICampaignFile): Observable<ICampaignFile> {
    const url = `${environment.fcApiUrl}${file.uris.CheckReviewState}`;
    return this.http.post<ICampaignFile>(url, {})
      .pipe(
        map(f => this.updateFields(f)),
        tap(f => this.replaceFile(f)),
      );
  }

  pauseOrResume(file: ICampaignFile): Observable<ICampaignFile> {
    const url = `${environment.fcApiUrl}${file.uris.PauseOrResume}`;
    return this.http.post<ICampaignFile>(url, {})
      .pipe(
        map(f => this.updateFields(f)),
        tap(f => this.replaceFile(f)),
      );
  }

  private calcSequence(prev: number, next: number): number {
    const epsilon = 1.e-5 * (next - prev);
    return random(prev + epsilon, next - epsilon);
  }

  recalculateSequence(): void {
    const reversed = this.campaignFiles.slice(0).reverse();
    reversed.map(
      (file, index) => {
        const prev = index > 0 ? reversed[index - 1].sequence : 1;
        const next = index < reversed.length - 1 ? reversed[index + 1].sequence : 1;
        file.sequence = this.calcSequence(prev, 0);
        return file;
      }
    );
    this._sortChanged = true;
  }

  updateSequence(): Observable<any> {
    const uri = `${environment.fcApiUrl}${this.currentOffer.uris.ArtworkSequence}`;
    const payload = {artworks: this.campaignFiles.map(f => ({uri: f.uri, sequence: f.sequence}))};
    if (!this._sortChanged) {
      return of(payload);
    }
    this.loading = true;
    return this.http.post<any>(uri, payload)
      .pipe(tap({
        complete: () => {
          this.loading = false;
          this._sortChanged = false;
        },
        error: () =>  this.loading = false,
      }));
  }

  private updateFields(f, thumbnailInProgress = false): ICampaignFile {
    return {
      ...f,
      _validResolution: true,
      thumbnail_inprogress: thumbnailInProgress,
      redirect_link: this.getUniqueUri(f.download_link, '-link'),
      download_link: this.getUniqueUri(f.download_link),
    };
  }

}
