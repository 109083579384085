import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import { FileService } from '../../services/file.service';
import { ControlService } from '../../services/control.service';
import { Router } from '@angular/router';
import { ICampaignFile } from '../../app.model';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PurchaseService } from '../../services/purchase.service';
import { ShopifyService } from '../../services/shopify.service';
import { FcAuthService } from '../../services/fc-auth.service';

const TARGET_PERCENTAGE = 16;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {

  @ViewChild('circle', {static: true}) circle: ElementRef;

  private destroy$ = new Subject();

  public percent = 0;
  public processing = true;

  constructor(
    private router: Router,
    private campaignSvc: CampaignService,
    private fileSvc: FileService,
    private controlSvc: ControlService,
    private purchaseSvc: PurchaseService,
    private shopifySvc: ShopifyService,
    private fcAuthSvc: FcAuthService,
  ) {}

  ngOnInit(): void {
    // this.purchaseSvc.verifyPurchase()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(
    //   data => {
    //     if (data && data.id) {
    //       this.shopifySvc.showToast('Thank you! Your purchase has been completed!');
    //       this.fcAuthSvc.getCurrentBalance()
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe();
    //     }
    //   }
    //);

    this.fcAuthSvc.getCurrentBalance()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.processing = false;
    this.setProgress(true);
    this.percent = TARGET_PERCENTAGE;
    this.setProgress();
  }

  get fileSrc(): Observable<string> {
    return new Observable(subscriber => {
      if (this.selectedArtwork) {
        this.fileSvc.getArtworkSrcAsync(this.selectedArtwork)
           .subscribe(
           redirectUrl => {
             subscriber.next(redirectUrl);
             subscriber.complete();
           });
       }
    });
  }

  get selectedArtwork(): ICampaignFile {
    return this.campaignSvc.selectedArtwork;
  }
  //
  // set selectedArtwork(artwork: ICampaignFile) {
  //   this.campaignSvc.selectedArtwork = artwork;
  //   this.fileSrc
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(url => this.campaignSvc.selectedArtworkSrc = url);
  // }

  setProgress(initial = false): void {
    if (this.circle) {
      const radius = this.circle.nativeElement.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      this.circle.nativeElement.style.strokeDasharray = `${circumference} ${circumference}`;
      this.circle.nativeElement.style.strokeDashoffset = `${circumference}`;
      this.circle.nativeElement.style.strokeDashoffset = circumference - (initial ? 0 : this.percent) / 100 * circumference;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
