<app-header [skipStepper]="true"></app-header>
<div class="top-header" *ngIf="!processing">
  <div>
    <div>
      <div class="indicator"
           [style.color]="'#03F7FF'"
           [attr.data-percent]="percent + '%'">
        <svg
           class="progress-ring"
           width="80"
           height="80">
          <circle
              class="progress-ring__bg"
              stroke="#dddddd"
              stroke-width="3"
              fill="transparent"
              r="28"
              cx="40"
              cy="40"/>
          <circle
              #circle
              class="progress-ring__circle"
              [style.stroke]="'#03F7FF'"
              stroke-width="3"
              fill="transparent"
              r="28"
              cx="40"
              cy="40"/>
        </svg>
      </div>
    </div>
    <div>
      <h1>On average companies advertising using DOOH<br>grew 16% last month according to JCD</h1>
    </div>
  </div>
</div>
<div class="content">

  <div class="Polaris-Card">
    <ng-container *ngIf="!processing">
      <h2 class="font-weight-light">GROW IN 4 EASY STEPS</h2>

      <div class="welcome">
        <div>
          <div class="inner">
            <div class="step">01</div>
            <div class="desc">Choose the PRODUCT to promote and measure.</div>
          </div>
        </div>
        <div>
          <div class="inner">
            <div class="step">02</div>
            <div class="desc">Create or upload ARTWORK to appear on a billboard.<br>

<!--              <a class="d-block mt-3" href="#">See Artwork Specs</a>-->
            </div>
          </div>
        </div>
        <div>
          <div class="inner">
            <div class="step">03</div>
            <div class="desc">Set BUDGET, TIME and LOCATION.</div>
          </div>
        </div>
        <div>
          <div class="inner">
            <div class="step">04</div>
            <div class="desc">Sit back and MEASURE UPLIFT.</div>
          </div>
        </div>
      </div>

      <div class="w-100 mt-5 p-5">
        <button class="Polaris-Button Polaris-Button--secondary Polaris-Button--sizeLarge d-block m-auto fc-btn"
                routerLink="/main">Let's get started...</button>
      </div>

      <div class="logos text-center mt-5">
        ACCESS OVER 100 000 SITES WORLDWIDE INCLUDING
      </div>
    </ng-container>
  </div>
</div>
<app-spinner *ngIf="processing"></app-spinner>
