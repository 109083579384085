import { Component, Input, OnInit } from '@angular/core';
import { DownloadMediaPlanService } from '@module/download-media-plan/download-media-plan.service';
import { ICampaign } from '../../app.model';

@Component({
  selector: 'app-download-media-plan',
  templateUrl: './download-media-plan.component.html',
  styleUrls: ['./download-media-plan.component.scss']
})
export class DownloadMediaPlanComponent {

   state: 'active'| 'wait' | 'not-ready';

  @Input() campaign: ICampaign;
  @Input() label = 'GET MEDIA PLAN';
  @Input() cls = 'Polaris-Button Polaris-Button--sizeLarge';

  constructor(
    private service: DownloadMediaPlanService,
  ) {
    this.state = 'active';
  }

  downloadReport(): void {
    if (this.state !== 'active') {
      return;
    }
    this.state = 'wait';
    this.service.downloadScreenReport(this.campaign).subscribe(
      () => {
        this.state = 'active';
      },
      () => {
        this.state = 'not-ready';
        setTimeout(() => this.state = 'active', 2000);
      }
    );
  }

}
