const ICON = 'https://storage.googleapis.com/fc-cdn/images/fc_marker_b.png';
const MILES_TO_KM = 1.6;

export class DistanceWidget extends google.maps.MVCObject {

  marker: google.maps.Marker;
  radiusWidget: RadiusWidget;

  constructor(
    public miles: number = 3,
  ) {
    super();
    this.marker = this.initMarker();
    this.initRadiusWidget();
  }

  public setMap(map: google.maps.Map): void {
    this.set('map', map);
    if (map) {
      this.set('position', map.getCenter());
    }
  }

  private initMarker(): google.maps.Marker {
    const marker = new google.maps.Marker({
      draggable: true,
      icon: {
        url: ICON,
        size: new google.maps.Size(26, 25),
        anchor: new google.maps.Point(13, 12)
      },
      title: 'Move me!'
    });
    marker.bindTo('map', this);
    marker.bindTo('position', this);
    return marker;
  }

  public getBounds(): google.maps.LatLngBounds {
    return this.get('bounds');
  }

  private initRadiusWidget(): void {
    this.radiusWidget = new RadiusWidget();
    this.radiusWidget.bindTo('center', this, 'position');
    this.radiusWidget.bindTo('map', this);
    this.radiusWidget.set('distance', RadiusWidget.getMiToKM(this.miles));
    this.bindTo('distance', this.radiusWidget);
    this.bindTo('bounds', this.radiusWidget);
  }

  public clear(): void {
    this.radiusWidget.clear();
    this.marker.setMap(null);
    delete this.radiusWidget;
    delete this.marker;
  }
}

export class RadiusWidget extends google.maps.MVCObject {

  circle: google.maps.Circle;
  sizer: google.maps.Marker;

  static getKMToMi(kmeters: number): number {
    return kmeters / MILES_TO_KM;
  }

  static getMiToKM(miles: number): number {
    return miles * MILES_TO_KM;
  }

  static distanceBetweenPoints(p1: google.maps.LatLng, p2: google.maps.LatLng): number {
    if (!p1 || !p2) {
      return 0;
    }
    const R = 6371; // Radius of the Earth in km
    const dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
    const dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  constructor() {
    super();
    this.circle = new google.maps.Circle({
      fillColor: '#03F7FF',
      strokeColor: '#03F7FF',
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: false,
      zIndex: 1,
    });

    // Bind the circle center to the RadiusWidget center property
    this.circle.bindTo('center', this);

    // Bind the circle map to the RadiusWidget map
    this.circle.bindTo('map', this);

    this.bindTo('bounds', this.circle);

    // Bind the circle radius property to the RadiusWidget radius property
    this.circle.bindTo('radius', this);
    this.sizer = this.addSizer();
  }


  private addSizer(): google.maps.Marker {
    const sizer = new google.maps.Marker({
      draggable: true,
      icon: {
        url: ICON,
        size: new google.maps.Size(26, 25),
        anchor: new google.maps.Point(13, 12)
      },
      title: 'Drag me!'
    });

    sizer.bindTo('map', this);
    sizer.bindTo('position', this, 'sizer_position');

    google.maps.event.addListener(sizer, 'drag', () => {
      this.setDistance();
    });
    return sizer;
  }

  setDistance(): void {
    const pos = this.get('sizer_position');
    const center = this.get('center');
    const distance = RadiusWidget.distanceBetweenPoints(center, pos);
    this.set('distance', distance);
  }

  center_changed(): void {
    const bounds = this.get('bounds');
    if (bounds) {
      const lng = bounds.getNorthEast().lng();
      const position = new google.maps.LatLng(this.get('center').lat(), lng);
      this.set('sizer_position', position);
    }
  }

  distance_changed(): void {
    this.set('radius', this.get('distance') * 1000);
  }

  public clear(): void {
    this.sizer.setMap(null);
    this.circle.setMap(null);
    delete this.sizer;
    delete this.circle;
  }

}
