import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSuffix'
})
export class ThousandSuffixPipe implements PipeTransform {

  /**
   * Displays number in floating point form.
   * @param value number passed
   * @param {number} decimals number of digits after comma
   * @returns {any}
   */
  transform(value: number, decimals: number = 1): any {

    let exp;
    const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];

    if (isNaN(value)) {
      return null;
    }

    if (value < 1000) {
      if (value - Math.floor(value) > 0) {
        return value.toFixed(decimals);
      } else {
        return value;
      }
    }

    exp = Math.floor(Math.log(value) / Math.log(1000));

    return (value / Math.pow(1000, exp)).toFixed(decimals) + suffixes[exp - 1];
  }

}
