<app-header></app-header>
<div class="content">
  <ng-container *ngIf="sessionReady | async">
    <div class="Polaris-Card flex-row flex-column">
      <div class="page-content flex-grow-1">
        <app-product-selector *ngIf="(currentStep | async) === 0"></app-product-selector>
        <app-artwork-generator *ngIf="(currentStep | async) === 1"></app-artwork-generator>
        <app-campaign-details *ngIf="(currentStep | async) === 2"></app-campaign-details>
        <app-campaign-summary *ngIf="(currentStep | async) === 3"></app-campaign-summary>
      </div>
      <app-footer *ngIf="step.footer"></app-footer>
    </div>
  </ng-container>
</div>
