import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './container/layout/layout.component';
import { RouterModule, Routes } from '@angular/router';
import { CreativeSpecsService } from './creative-specs.service';
import { SpecsComponent } from './components/specs/specs.component';
import { FormatNamePipe } from './format-name.pipe';
import { FormatSpecsComponent } from './components/format-specs/format-specs.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    // LayoutComponent,
    SpecsComponent,
    FormatNamePipe,
    FormatSpecsComponent,
  ],
  exports: [
    FormatSpecsComponent
  ],
  providers: [
    CreativeSpecsService,
  ]
})
export class CreativeSpecsModule { }
