import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  ICompany,
  ICompanyBudget,
  ICredentials,
  ISessionData,
  ISessionResponse,
  IShopifyRegisterCompany,
  IUser
} from '../app.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { IShopifyShop } from '../models/shopify.model';
import { Router } from '@angular/router';
import { UserState, UserStatus } from './userstate';

const FC_TOKEN_KEY = 'fc_token';
const DEFAULT_LOCATION_URI = '/data/locations?defaults=1';
const SHOPIFY_COMPANY_REGISTER_URL = '/data/shopify/signup';
const COMPLETE_REGISTRATION = '/complete-registration';
export const DEFAULT_PROFILE_SET = 'shopify';
export const CUSTOM_TOKEN_AUTH_KEY = 'X-FlowCity-Custom-Token';

@Injectable({
  providedIn: 'root'
})
export class FcAuthService {

  private _isAuth = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this._isAuth.asObservable();

  private _currentSession$ = new BehaviorSubject<ISessionData>(null);
  public currentSession$ = this._currentSession$.asObservable();

  private _currentBalance$ = new BehaviorSubject<number>(null);
  public currentBalance$ = this._currentBalance$.asObservable();

  public firebaseUser$ = new BehaviorSubject<UserState>(new UserState(undefined));

  constructor(
    private http: HttpClient,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
  ) {
  }

  initFirebase(): void {
    this.angularFireAuth.authState.subscribe(user => this.authStateObserver(user));
  }

  authStateObserver(user: firebase.User | null): void {
    console.log(user);
    if (user) {
        this.firebaseUser$.next(new UserState(user));
        this._isAuth.next(true);
    } else {
        this.firebaseUser$.next(new UserState(null));
        this._isAuth.next(false);
    }
  }

  firebaseAuthState(callback): void {
    this.angularFireAuth.onAuthStateChanged(user => callback(user));
  }

  signInJWT(loginBody: ICredentials): Observable<any> {
    return new Observable(subscriber => {
      this.angularFireAuth.signInWithCredential(firebase.auth.EmailAuthProvider.credential(loginBody.email, loginBody.password))
        .then(response => {
          this.authStateObserver(response.user);
          response.user.getIdToken().then(idToken => {
            subscriber.next();
            subscriber.complete();
          });
        }).catch(err => {
          subscriber.error(err);
        });
    });
  }

  signInWithCustomToken(token: string): Observable<any> {
    return from(this.angularFireAuth.signInWithCustomToken(token));
  }

  signOut(): Observable<any> {
    return new Observable(subscriber => {
      this.angularFireAuth.signOut()
        .then(response => {
          subscriber.next(response);
          subscriber.complete();
        })
        .catch(err => {
          subscriber.error(err);
          subscriber.complete();
        });
    });
  }

  public getSession(): Observable<ISessionData> {
    let sessionData;
    return this.http.get<ISessionResponse>(`${ environment.fcAuthService }`)
      .pipe(
        map(data => data.sessionData),
        tap(data => {
          sessionData = data;
          this.getLocation(`${ data.user.department }/locations`)
            .pipe(
              switchMap(d => d.length ? of(d) : this.getLocation(DEFAULT_LOCATION_URI)),
              map(d => ({...sessionData, location: d[0] })),
            ).subscribe(d => this._currentSession$.next(d));
        }),
        tap(data => this._currentSession$.next(data)),
        tap(() => { this.completeRegistration().subscribe(); })
      );
  }

  public refreshSessionData(): Observable<ISessionResponse> {
    let sessionData;
    return this.http.delete(`${environment.fcAuthService}/cache`)
      .pipe(
        switchMap(() => this.http.get<ISessionResponse>(`${environment.fcAuthService}`)),
        map(data => data.sessionData),
        tap(data => sessionData = data),
        map(data => ({...sessionData, location: this.location })),
        tap(data => this._currentSession$.next(data))
      );
  }

  private getLocation(uri: string): Observable<any> {
    return this.http.get(`${ environment.fcApiUrl }${uri}`);
  }

  public get session(): ISessionData {
    return this._currentSession$.getValue();
  }

  public get sessionReady(): Observable<boolean> {
    return of(this._currentSession$.getValue() !== null);
  }

  public get company(): ICompany {
    return this.session.company;
  }

  public get companyId(): number {
    return Number(this.company.uri.split('/').pop());
  }

  public get shopifyConfig(): any {
    return JSON.parse(this.company.custom_data).shopify;
  }

  public get firstTimeLogin(): boolean {
    return this.user.first_time_login;
  }

  public get user(): IUser {
    return this.session.user;
  }

  public get location(): any {
    return this.session.location;
  }

  public get departmentUri(): string {
    return this.user.department;
  }

  public registerCompany(payload: IShopifyRegisterCompany): Observable<any> {
    return this.http.post<HttpResponse<any>>(`${environment.fcApiUrl}${SHOPIFY_COMPANY_REGISTER_URL}`, payload, { observe: 'response' })
    .pipe(
      switchMap(response => this.signInWithCustomToken(response.headers.get(CUSTOM_TOKEN_AUTH_KEY))),
      tap(() => this._isAuth.next(true)),
      switchMap(() => this.getSession()),
      mergeMap(() => this.completeRegistration()),
      switchMap(() => this.getToken$())
    );
  }

  // public getToken$(): Observable<firebase.auth.IdTokenResult> {
  //   return this.firebaseUser$.pipe(
  //     map(user => user.value),
  //     switchMap((user: any) => {
  //       if (user) {
  //         return from(user.getIdTokenResult(true)).pipe(map(token => {
  //           return token;
  //         }));
  //       } else {
  //         return of(null);
  //       }
  //     }),
  //     tap(user => this._isAuth.next(user !== null)),
  //     );
  // }

  public getToken$(): Observable<firebase.auth.IdTokenResult> {
    const user = this.firebaseUser$.getValue().value;
    if (user) {
      return from(user.getIdTokenResult(true)).pipe(map(token => {
        return token;
      }));
    } else {
      return of(null);
    }
  }

  public completeRegistration(): Observable<any> {
    if (environment.localDev) {
      return of(true);
    }
    return this.http.post(`${environment.apiUrl}${COMPLETE_REGISTRATION}`, { company_id: this.companyId });
  }

  public setupSession(shop: IShopifyShop): Observable<any> {
    return this.registerCompany({
      name: shop.name,
      email: shop.email,
      shop: shop.myshopify_domain,
      profiles_set: DEFAULT_PROFILE_SET,
    });
  }

  public goToStartPage(): void {
    this.router.navigateByUrl(!this.firstTimeLogin ? '/your-campaigns' : '/welcome');
  }

  public getCurrentBalance(): Observable<ICompanyBudget> {
    return this.http.get<ICompanyBudget>(`${ environment.fcApiUrl }/data/companies/${ this.companyId }/current-balance`)
      .pipe(tap(data => data && data.remaining_budget >= 0 && this._currentBalance$.next(data.remaining_budget)));
  }

  public getCampaignRedirectUrl(campaignId): string {
    return `/data/companies/${ this.companyId }/offers/${ campaignId }`;
  }

}
