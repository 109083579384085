import { Component, OnInit } from '@angular/core';
import { ShopifyService } from './services/shopify.service';
import { ControlService } from './services/control.service';
import { Observable, of } from 'rxjs';
import { FcAuthService } from './services/fc-auth.service';
import { filter, switchMap } from 'rxjs/operators';
import { IShopifyShop } from './models/shopify.model';
import { Router } from '@angular/router';
import { UserStatus } from './services/userstate';
import { environment } from '@env/environment';
import { PurchaseService } from './services/purchase.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public appInitialized = false;
  url;
  shopDomain;
  host;
  chargeId;
  campaignId;

  constructor(
    private flowCityAuth: FcAuthService,
    private controlSvc: ControlService,
    private service: ShopifyService,
    private router: Router,
    private purchaseSvc: PurchaseService,
  ) {
    this.flowCityAuth.firebaseUser$
      .pipe(filter(user => user.status !== UserStatus.Unknown),
        switchMap(() => this.initialize()),
          switchMap(() => this.flowCityAuth.getSession())
        )
      .subscribe(
        data => {
          this.appInitialized = true;
          if (this.chargeId) {
            window.history.pushState('', document.title, this.url.toString());
            this.verifyPurchase(this.chargeId);
          }
          if (this.campaignId) {
            this.router.navigateByUrl(`/your-campaigns/${ this.campaignId }`);
          } else {
            this.flowCityAuth.goToStartPage();
          }
        },
        err => {
          this.router.navigateByUrl('/signin', {state: {error: err.reason}});
        });
  }

  ngOnInit(): void {
    this.url = new URL(document.location.href);
    const params = this.url.searchParams;
    this.host = params.get('host');
    this.shopDomain = params.get('shop');
    this.chargeId = params.get('charge_id');
    this.campaignId = params.get('campaign_id');
    this.service.shop = this.shopDomain;
    this.service.host = this.host;
    params.delete('charge_id');
    params.delete('campaign_id');

    if (environment.localDev) {
      this.router.navigateByUrl('/signin');
      return;
    }

    if (!this.shopDomain || !this.host) {
      location.href = 'https://www.flow.city';
    } else {
      this.flowCityAuth.initFirebase();
    }

  }

  private verifyPurchase(chargeId): void {
    this.service.showToast('Checking your payment status...');
    this.purchaseSvc.verifyPurchase(chargeId)
      .subscribe(
      data => {
        if (data && data.id) {
          setTimeout(() => {
            this.service.showToast('Thank you! Your purchase has been completed!');
            this.flowCityAuth.getCurrentBalance().subscribe();
          }, 8000);
        }
      });
  }

  get currentStep(): Observable<number> {
    return this.controlSvc.currentStep$;
  }

  get step(): any {
    return this.controlSvc.step;
  }

  get isAuthenticated(): Observable<boolean> {
    return this.flowCityAuth.isAuthenticated;
  }

  get sessionReady(): Observable<boolean> {
    return this.flowCityAuth.sessionReady;
  }

  get shop(): IShopifyShop {
    return this.service.shopEntity;
  }

  initialize(): Observable<any> {
    if (environment.localDev) {
      return this.flowCityAuth.getToken$()
        .pipe(
          switchMap(token => {
            console.log(token);
            return !token ? this.flowCityAuth.setupSession(this.service.shopEntity) : of(token)
          }),
        );
    }

    return this.service.shopifyInit(this.host, this.shopDomain)
      .pipe(
        switchMap(() => this.flowCityAuth.getToken$()),
        switchMap(token => !token ? this.flowCityAuth.setupSession(this.service.shopEntity) : of(token)),
      );
  }

}
