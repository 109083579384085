import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';

export const ARTWORK_GENERATOR_URL = '/data/artwork-generator';

@Injectable()
export class ArtworkGeneratorService {

  private _change = new BehaviorSubject(false);
  public change = this._change.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  generateArtwork(template: string, data: any): Observable<any> {
    const payload = {
      template,
      items: [data],
    };
    return this.http.post(`${env.fcApiUrl}${ARTWORK_GENERATOR_URL}`, payload);
  }

  getLayoutConfig(template: string): any {
    const uri = `https://storage.googleapis.com/${ env.fcApp }/generators/${ template }/config.json`;
    return this.http.get(uri);
  }

}
