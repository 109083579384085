import { Injectable } from '@angular/core';
import { IPurchasePackage } from '../app.model';
import { PACKAGES } from '../shared/mock';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(
    private http: HttpClient,
  ) { }

  get packages(): Observable<IPurchasePackage[]> {
    return this.http.get<IPurchasePackage[]>(`${ environment.apiUrl }/packages`);
  }

  makePurchase(pckg: IPurchasePackage, shop: string, host: string, campaignId = null): Observable<any> {
    // Set return URL
    let returnUrl = `${ environment.appUrl }?shop=${ shop }&host=${ host }`;
    if (campaignId) {
      returnUrl = `${returnUrl}&campaign_id=${campaignId}`;
    }

    const payload = {
      price: pckg.priceUSD,
      name: pckg.name,
      credits: pckg.amount,
      return_url: returnUrl,
    };
    return this.http.post(`${ environment.apiUrl }/purchase`, payload);
  }

  verifyPurchase(chargeId): Observable<any> {
    return chargeId ? this.http.get (`${ environment.apiUrl }/purchase/verify/${ chargeId }`) : of({purchase: false});
  }

}
