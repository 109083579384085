import { Component, OnInit } from '@angular/core';
import { ControlService } from '../../services/control.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private controlSvc: ControlService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  get step(): any {
    return this.controlSvc.step;
  }

  prev(): void {
    this.resetDisabledState();
    if (this.controlSvc.currentStep > 0) {
      this.controlSvc.currentStep = this.controlSvc.currentStep - 1;
    } else {
      this.router.navigateByUrl('/your-campaigns');
    }
  }

  get isNextDisabled(): Observable<boolean> {
    return this.controlSvc.nextDisabled$;
  }

  get isPrevDisabled(): Observable<boolean> {
    return this.controlSvc.prevDisabled$;
  }

  next(): void {
    this.resetDisabledState();
    this.controlSvc.next$.next(true);
  }

  private resetDisabledState(): void {
    this.controlSvc.nextDisabled = false;
    this.controlSvc.prevDisabled = false;
  }

}
