<app-header [skipStepper]="true"></app-header>
<div class="content">

  <div class="Polaris-Card">

    <h2 class="font-weight-light">CHOOSE THE PACKAGE</h2>

    <div class="purchase-box">
      <div class="purchase-block" *ngFor="let pckg of packages; let i = index;"
           [class.selected]="i === selected"
           (click)="select(i)">
        <div>
          <div class="amount">${{ pckg.priceUSD }}</div>
          <div class="credits">£{{ pckg.amount }} to spend</div>
          <div class="desc" [innerHTML]="pckg.desc"></div>
        </div>
      </div>
    </div>

    <div class="w-100 mt-2 p-5">
      <button class="Polaris-Button Polaris-Button--secondary Polaris-Button--sizeLarge d-block m-auto fc-btn"
              *ngIf="selected !== null"
              (click)="purchase()">Buy and go live!</button>
    </div>

    <app-footer>
      <button class="Polaris-Button float-left"
              (click)="prev()">Back
      </button>
    </app-footer>

  </div>
</div>
<app-spinner *ngIf="processing"></app-spinner>

