<ng-container *ngIf="showGraph">
  <div class="graph">
    <div class="graph-bar"
         *ngFor="let i of days"
         [ngStyle]="getStyle(output[i])">
      <div class="info">
        <div class="date">{{ i }}</div>
        <div class="impressions">{{ output[i].impressions | thousandSuffix }}</div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="noData">
  <div class="text-center">No data available</div>
</ng-container>
